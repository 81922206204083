/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 2/6/2020
 * @Example
 */

import React from "react";
import { bindActionCreators, compose } from "redux";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import Modal from "../../../common/modal/modal";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import { FormGroup, Row, Col } from "react-bootstrap";
import Filter from "../../../filter/filter";
import { Combo } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import moment from "moment";
import { browserHistory } from "react-router";
import W09F2070Popup from "./W09F2070Popup";
import { ButtonIcon, Typography, Avatar, Button } from "diginet-core-ui/components";
import Icon from "diginet-core-ui/icons";
import _ from "lodash";

import * as W09F2070Actions from "../../../../redux/W0X/W09F2070/W09F2070_actions";
import Status from "../../../common/status/status";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";

const styles = theme => ({
    dateInfo: {
        display: "flex",
        alignItems: "center",
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
});

class W09F2070 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            mode: "add",
            rowData: null,
            showW09F2070Popup: false,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            dataCboProjects: {
                rows: [],
                total: 0,
            },
            loading: {
                cboEmployees: false,
            },
            showW84F3005: false,
        };
        this.loading = {
            cboEmployees: false,
        };
        this.filter = {
            ProDateFrom: "",
            ProDateTo: "",
            Employee: "%",
            Project: "%",
            AppStatusID: "%",
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
        this.filterCboProjects = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2070", isPer => {
            this.setState({ iPermission: isPer });
        });
    };

    onSearch = () => {
        this.loadDataGrid();
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: data.EmployeeID },
        });
    };

    loadRewardCaption = () => {
        this.props.w09f2070Actions.loadRewardCaption({ Languaghe: Config.language || "84" }, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    renderEmpProfile = e => {
        const { data } = e.row;
        const date = moment(data.ProposalDate).format("LT, DD/MM/YYYY");

        const info = {
            [Config.lang("Nhan_vien")]: `${data?.EmployeeID || ""} - ${data?.EmployeeName || "---"}`,
            [Config.lang("Phong_ban")]: data?.DepartmentName || "",
            [Config.lang("Du_an")]: data?.ProjectName || "",
            [Config.lang("Chuc_vu")]: data?.DutyName || "",
            [Config.lang("Ngay_vao_lam")]: Config.convertDate(data?.DateJoined, "", "L"),
        };

        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div onClick={() => this.onChangePageProfile(data)}>
                    <Avatar
                        src={Config.getUserPicture(data.UserPictureURL)}
                        data={info}
                        readOnly
                        hoverAble
                        className={"mgr15"}
                    />
                </div>
                <div className={"w75f2000_info"}>
                    <Typography type={"h3"}>{data?.EmployeeName}</Typography>
                    <Typography>{data.DepartmentName || ""}</Typography>
                    <div className={"display_row align-center mgt5"}>
                        <Icon
                            name={"calendar"}
                            width={20}
                            height={20}
                            className={"mgr5"}
                            color={Config.coreTheme?.colors?.system.rest}
                        />
                        <Typography
                            type={"p2"}
                            className={"display_row align-center mgt5"}
                            color={Config.coreTheme?.colors?.system.rest}
                        >
                            {data.ProposalDate && date}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const { data } = e.row;
        return (
            <div className={"pdt10 pdb10"}>
                <Typography lineClamp={3} hoverTooltip>
                    {data.ProNotice || ""}
                </Typography>
                <Typography>
                    {Config.lang("Du_an_cong_trinh")}: {data.ProjectName || ""}
                </Typography>
                {data.ProposerName && (
                    <Typography>
                        {Config.lang("Nguoi_lap")}: {data.ProposerName || ""}
                    </Typography>
                )}
                {data.ApproverName && (
                    <Typography>
                        {Config.lang("Nguoi_duyet")}: {data.ApproverName || ""} - {data.DepartmentID || ""} -{" "}
                        {data.ApprovalDate || ""}
                    </Typography>
                )}
                {data.AppStatusID > 0 && (
                    <Typography>
                        {Config.lang("Ghi_chu_duyet")}: {data.ApprovalNotes || ""}
                    </Typography>
                )}
            </div>
        );
    };

    renderStatus = e => {
        const { data } = e.row;

        return <Status data={data} />;
    };

    onAdd = () => {
        this.setState({
            mode: "add",
            rowData: null,
            showW09F2070Popup: true,
        });
    };

    onView = e => {
        const { data } = e.row;
        this.setState({
            mode: "view",
            rowData: data,
            showW09F2070Popup: true,
        });
    };

    onDelete = data => {
        const { ProTransID } = data;

        const param = {
            ProTransID,
        };

        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            this.props.w09f2070Actions.deleteDataGrid(param, error => {
                if (error) {
                    Config.popup.show("INFO", Config.lang("Loi_chua_xac_dinh"));
                    return false;
                } else {
                    Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
                    this.loadDataGrid();
                }
            });
        });
    };

    onViewHistory = e => {
        this.setState({ showW84F3005: true, rowData: e.data });
    };

    renderAction = e => {
        const { data } = e.row;
        return (
            <div className={"display_row align-center"}>
                <ButtonIcon
                    circular
                    viewType={"text"}
                    name={"History"}
                    onClick={() => this.onViewHistory(e)}
                    className={"mgr5"}
                />
                <ButtonIcon
                    circular
                    viewType={"text"}
                    name={"View"}
                    className={"mgr5"}
                    onClick={() => this.onView(e)}
                />
                <ButtonIcon
                    circular
                    viewType={"text"}
                    name={"Delete"}
                    disabled={Number(data?.OnlyWatch) === 1 || Number(data?.AppStatusID) !== 0}
                    onClick={() => this.onDelete(data)}
                />
            </div>
        );
    };

    filterChange = (key, data) => {
        const { value } = data;
        if (!key) return false;
        switch (key) {
            case "DateFrom":
                this.filter.ProDateFrom = value;
                break;
            case "DateTo":
                this.filter.ProDateTo = value;
                break;
            case "EmployeeID":
                this.filter.Employee = value ?? "%";
                break;
            case "ProjectID":
                this.filter.Project = value ?? "%";
                break;
            case "AppStatusID":
                this.filter.AppStatusID = value ?? "%";
                break;
            default:
                break;
        }
    };

    renderFilter = () => {
        const { getCboAppStatus, classes } = this.props;
        const { ProDateTo, ProDateFrom, Project, AppStatusID, Employee } = this.filter;
        const { dataCboEmployees, dataCboProjects, loading } = this.state;
        return (
            <Filter
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className={classes.divText}>{Config.lang("Tu")}</div>
                                            <DateBoxPicker
                                                dateBoxProps={{ max: ProDateTo }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("DateFrom", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={ProDateFrom}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className={classes.divText}>
                                                {Config.lang("Den").toLowerCase()}
                                            </div>
                                            <DateBoxPicker
                                                dateBoxProps={{ min: ProDateFrom }}
                                                style={{ marginLeft: "5px" }}
                                                onValueChanged={e => this.filterChange("DateTo", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={ProDateTo && ProDateTo}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => this.filterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={AppStatusID === "%" ? "" : AppStatusID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            value={Employee === "%" ? null : Employee}
                                            loading={loading.cboEmployees}
                                            stylingMode={"outlined"}
                                            label={Config.lang("Nhan_vien")}
                                            itemRender={e => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.EmployeeID + " - " + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.filterChange("EmployeeID", e)}
                                            onInputChanged={e => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={e => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboProjects}
                                            skip={this.filterCboProjects.skip}
                                            limit={this.filterCboProjects.limit}
                                            displayExpr={"ProjectName"}
                                            keyExpr={"ProjectID"}
                                            valueExpr={"ProjectID"}
                                            value={Project === "%" ? null : Project}
                                            loading={loading.cboProjects}
                                            stylingMode={"outlined"}
                                            label={Config.lang("Du_an")}
                                            itemRender={e => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.ProjectID + " - " + data.ProjectName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.filterChange("ProjectID", e)}
                                            onInputChanged={e => {
                                                this.filterCboProjects.strSearch = e.target.value;
                                                this.filterCboProjects.skip = 0;
                                                this.loadCboProjects(true);
                                            }}
                                            onLoadMore={e => {
                                                this.filterCboProjects.skip = e.skip;
                                                this.filterCboProjects.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "38px",
                                    paddingBottom: "40px",
                                }}
                            >
                                <ButtonGeneral
                                    name={Config.lang("Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadRewardCaption();
        this.loadCboEmployees();
        this.loadCboProjects();
        this.loadDataGrid();
        this.loadCboAppStatus();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { getDataGrid } = this.props;
        Config.callChildForm(
            {
                FormID: "W09F2070",
                // ID: voucher_id,
                keyExpr: "ProTransID",
                data: getDataGrid && getDataGrid.rows ? getDataGrid.rows : [],
                onLoad: params => this.loadDataGrid(params && params.ID),
                onAction: (e, data) => this.onView({ row: { data: data } }),
            },
            this.props
        );
    }

    loadCboEmployees = isReset => {
        const param = {
            Type: "EmployeeID",
            FormID: "W09F2070",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.loading.cboEmployees = true;
        this.setState({ loading: this.loading });
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.loading.cboEmployees = false;
            this.setState({ loading: this.loading });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    loadCboProjects = isReset => {
        const param = {
            HostID: "",
            FormID: "W09F2070",
            Language: Config.language || "84",
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch,
        };
        this.loading.cboProjects = true;
        this.setState({ loading: this.loading });
        this.props.generalActions.getCboProjects(param, (error, data) => {
            this.loading.cboProjects = false;
            this.setState({ loading: this.loading });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboProjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    loadDataGrid = VoucherID => {
        const { voucher_id } = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { Employee, Project, AppStatusID, ProDateFrom, ProDateTo, skip, limit } = this.filter;
        const param = {
            TransID: VoucherID || "",
            FormID: "W09F2070",
            Language: Config.language || 84,
            ProDateFrom: ProDateFrom,
            ProDateTo: ProDateTo,
            EmployeeID: Employee && Employee.EmployeeID ? Employee.EmployeeID : "%",
            ProjectID: Project && Project.ProjectID ? Project.ProjectID : "%",
            AppStatusID: _.toString(AppStatusID),
            skip: skip,
            limit: limit,
        };
        this.setState({ gridLoading: true });
        this.props.w09f2070Actions.loadDataGrid(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W09F2070",
            Language: Config.language || 84,
        };

        this.props.w09f2070Actions.loadAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    onCloseModal = (isSaved = false) => {
        if (isSaved) this.loadDataGrid();
        this.setState({
            showW09F2070Popup: false,
        });
    };

    render() {
        const { getDataGrid } = this.props;
        const { iPermission, gridLoading, showW09F2070Popup, mode, rowData, showW84F3005 } = this.state;
        if (!iPermission) return null;
        const { skip, limit } = this.filter;
        return (
            <>
                {showW84F3005 && (
                    <W84F3005
                        open={showW84F3005}
                        onClose={() => this.setState({ showW84F3005: false })}
                        FormID="W09F2070"
                        TransID={rowData?.ProTransID ?? ""}
                    />
                )}
                <Modal
                    open={showW09F2070Popup}
                    maxWidth={"md"}
                    fullWidth={true}
                    onClose={() => this.onCloseModal(false)}
                    title={Config.lang("Lap_de_xuat_ky_luat")}
                >
                    <W09F2070Popup mode={mode} data={rowData} onClose={this.onCloseModal} />
                </Modal>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("De_xuat_ky_luat")}>
                    <Button
                        color={"primary"}
                        viewType={"filled"}
                        label={Config.lang("Them")}
                        startIcon={"AddCircle"}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <GridContainer
                    style={{ border: "none" }}
                    dataSource={getDataGrid && getDataGrid.rows}
                    keyExpr={"ProTransID"}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    noDataText={Config.lang("No_data")}
                    onCellClick={this.onDetail}
                    loading={gridLoading}
                    hoverStateEnabled={true}
                    totalItems={getDataGrid && Number(getDataGrid.total)}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    height={Config.getHeightGrid()}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column cellRender={this.renderEmpProfile} minWidth={150} width={"20%"} />
                    <Column cellRender={this.renderInfo} width={"55%"} />
                    <Column cellRender={this.renderStatus} alignment={"center"} minWidth={100} width={"10%"} />
                    <Column cellRender={this.renderAction} minWidth={100} width={"15%"} />
                </GridContainer>
            </>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboAppStatus: state.W09F2070.getCboAppStatus,
            getDataGrid: state.W09F2070.getDataGrid,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w09f2070Actions: bindActionCreators(W09F2070Actions, dispatch),
        })
    ),
    withStyles(styles)
)(W09F2070);
