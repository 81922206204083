/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/12/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as W09F2070Actions from "../../../../redux/W0X/W09F2070/W09F2070_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup } from "react-bootstrap";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import { Combo, MForm, TextField } from "../../../common/form-material";
import * as generalActions from "../../../../redux/general/general_actions";
import moment from "moment";
import DateBoxPicker from "../../../common/form-material/date-box"
import Attachments from "../../../common/attachments/attachments";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Modal from "../../../common/modal/modal";
import FormLabel from "@material-ui/core/FormLabel";
import { FormControl, InputLabel } from "@material-ui/core";
import _ from 'lodash';
import NumberFormat from "react-number-format";
import { Avatar, Dropdown, Typography } from 'diginet-core-ui/components';

const styles = {
    avatar: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        border: "1px solid #ddd",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB"
    }
};

class W09F2070Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        const proCoefficients = this.convertProCoefficients();
        this.state = {
            showW09F2070Popup: false,
            dataGrid: [],
            disabled: false,
            error: {},
            loading: false,
            uploading: false,
            dataOldAttachments: [],
            dataForm: {
                Employee: null,
                ProDisRewardFormID: "",
                ProDisRewardLevelID: "",
                DisViolationActGroupID: "",
                DisViolationActID: "",
                ProContent: "",
                ProNotice: "",
                ValidDate: moment().format("YYYY-MM-DD"),
                ValidEndDate: null,
                NumMonth: 0,
                ...proCoefficients
            },
            employeeLoading: false,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
        };
        this.attachments = [];
        this.deletedFile = [];
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
    }


    loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W09F2070",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.setState({ employeeLoading: true });
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({ employeeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const _d = data && data.rows ? data.rows : [];
                const total = data && data.total ? data.total : 0;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? _d : dataCboEmployees.rows.concat(_d),
                        total: total,
                    },
                });
            }
        });
    };

    loadCboDisRewardForm = () => {
        this.props.w09f2070Actions.loadCboDisRewardForm(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboProDisRewardLevel = () => {
        this.props.w09f2070Actions.loadCboProDisRewardLevel(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboDisViolationActGroup = () => {
        this.props.w09f2070Actions.loadCboDisViolationActGroup(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboDisViolationAct = () => {
        const { dataForm } = this.state;
        const params = {
            DisViolationActGroupID: dataForm && dataForm.DisViolationActGroupID ? dataForm.DisViolationActGroupID : ""
        };
        this.props.w09f2070Actions.loadCboDisViolationAct(params, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadForm = () => {
        const { data } = this.props;
        const param = {
            ProTransID: data && data.ProTransID ? data.ProTransID : ""
        };
        this.setState({ loading: true });
        this.props.w09f2070Actions.loadForm(param, (error, data) => {
            this.setState({ loading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const proCoefficients = this.convertProCoefficients(data);
                const user = Config.getUser({ EmployeeID: data.EmployeeID });
                this.setState({
                    dataForm: {
                        ...data,
                        Employee: user ? user : data,
                        ...proCoefficients
                    }
                }, () => {
                    this.loadCboDisViolationAct();
                });
            }
        });
    };

    loadRequiredFields = () => {
        const params = {
            FormID: 'W09F2070',
            ModuleID: '09',
            CheckMode: 0
        };
        this.setState({ loading: true });
        this.props.generalActions.getRequiredFields(params, error => {
            this.setState({ loading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    convertProCoefficients = data => {
        const { getRewardCaption } = this.props;
        const proCoefficientArr = {};
        if (getRewardCaption) {
            getRewardCaption.forEach(item => {
                if (item.Disabled === 0) {
                    proCoefficientArr[item.Code] = data ? data[item.Code] : 0;
                }
            });
        }
        return proCoefficientArr;
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        switch (key) {
            case "DisViolationActGroupID":
                this._setDataForm(key, e.value, () => {
                    this.loadCboDisViolationAct();
                });
                break;
            case "ValidDate":
                this._setDataForm(key, e.value, () => {
                    const { ValidDate, ValidEndDate, NumMonth } = this.state.dataForm;
                    if (ValidDate) {
                        if (ValidEndDate) {
                            const _NumMonth = moment(ValidEndDate).diff(moment(ValidDate), 'days');
                            this._setDataForm("NumMonth", Math.round(_NumMonth / 30));
                        } else if (NumMonth) {
                            const NumDays = NumMonth * 30;
                            const _ValidEndDate = moment(ValidDate).add(NumDays, 'days').format("YYYY-MM-DD");
                            this._setDataForm("ValidEndDate", _ValidEndDate);
                        }
                    }
                });
                break;
            case "ValidEndDate":
                this._setDataForm(key, e.value, () => {
                    const { ValidDate, ValidEndDate, NumMonth } = this.state.dataForm;
                    if (ValidEndDate) {
                        if (ValidDate) {
                            const _NumMonth = moment(ValidEndDate).diff(moment(ValidDate), 'days');
                            this._setDataForm("NumMonth", Math.round(_NumMonth / 30));
                        } else if (NumMonth) {
                            const NumDays = NumMonth * 30;
                            const _ValidDate = moment(ValidEndDate).subtract(NumDays, 'days').format("YYYY-MM-DD");
                            this._setDataForm("ValidDate", _ValidDate);
                        }
                    }
                });
                break;
            case "NumMonth":
                const _val = e.target.value < 0 ? 0 : e.target.value;
                this._setDataForm(key, _val, () => {
                    const { ValidDate, ValidEndDate, NumMonth } = this.state.dataForm;
                    if (NumMonth) {
                        if (ValidDate) {
                            const NumDays = NumMonth * 30;
                            const _ValidEndDate = moment(ValidDate).add(NumDays, 'days').format("YYYY-MM-DD");
                            this._setDataForm("ValidEndDate", _ValidEndDate);
                        } else if (ValidEndDate) {
                            const NumDays = NumMonth * 30;
                            const _ValidDate = moment(ValidEndDate).subtract(NumDays, 'days').format("YYYY-MM-DD");
                            this._setDataForm("ValidDate", _ValidDate);
                        }
                    }
                });
                break;
            case "EmployeeID":
                this._setDataForm("Employee", e.data);
                break;
            default:
                let value = e.value || e.target?.value || "";
                if (key.indexOf("ProCoefficient") > -1 && value) {
                    value = Math.abs(Number(value));
                }
                this._setDataForm(key, value);
                break;
        }
    };

    componentDidMount() {
        const { mode, data } = this.props;
        this.loadCboDisRewardForm();
        this.loadCboProDisRewardLevel();
        this.loadCboDisViolationActGroup();
        this.loadRequiredFields();
        if (mode === "view") {
            this.loadForm();
            this.loadAttachments();
            if (data && (Number(data.AppStatusID) !== 0)) {
                this.setState({ disabled: true });
            }
        } else {
            this.loadCboEmployees();
            this.loadCboDisViolationAct();
        }
    }

    _setDataForm = (key, value, cb) => {
        if (!key) return false;
        this.setState({
            dataForm: {
                ...this.state.dataForm,
                [key]: value
            }
        }, () => {
            if (cb) cb();
        });
    };

    setStateErrorText(objValue, key) {
        const stateError = key ? key : "error";
        this.setState({
            [stateError]: {
                ...(this.state[stateError] ? this.state[stateError] : {}),
                ...objValue
            }
        });
        return Object.keys(objValue).length !== 0;
    }

    onSave = () => {
        const { mode, data, getRequiredFields } = this.props;
        const isEdit = mode === "view";
        const { dataForm, dataOldAttachments } = this.state;
        const listDataForm = Object.keys(dataForm);
        let dataRequired = ["EmployeeID", "ValidDate"];
        const filterAttachment = getRequiredFields && getRequiredFields.filter(item =>
            item.SqlFieldName === 'Attachment'
        );
        getRequiredFields && getRequiredFields.forEach((field) => {
            if ((field.ValidMode && field.ValidMode === "O"
                && field.SqlFieldName && listDataForm.indexOf(field.SqlFieldName) > -1
                && dataRequired.indexOf(field.SqlFieldName) < 0)
                || ((filterAttachment[0].ValidMode === "O" && _.isEmpty(this.attachments) && _.isEmpty(dataOldAttachments)) ? (field.SqlFieldName === 'Attachment') : '')
            ) {
                dataRequired.push(field.SqlFieldName);
            }
        });
        //validate form..
        const validateRules = [];
        dataRequired && dataRequired.forEach((item) => {
            let rule = {};
            switch (item) {
                case "EmployeeID":
                    rule = {
                        name: "EmployeeID",
                        rules: ["isRequired"],
                        value: dataForm && dataForm.Employee && dataForm.Employee.EmployeeID ? dataForm.Employee.EmployeeID : ""
                    };
                    break;
                default:
                    rule = {
                        name: item,
                        rules: ["isRequired"],
                        value: dataForm[item]
                    };
                    break;
            }
            validateRules.push(rule);
        });
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            const arrAttachments = this._getAttachments();
            const params = {
                HREmployeeID: Config.getHREmployeeID(),
                ProTransID: data && data.ProTransID ? data.ProTransID : "",
                ProposalDate: data && data.ProposalDate ? data.ProposalDate : "",
                ProTeamID: "",
                ProDepartmentID: "",
                ProAuthority: "",
                ProResponsibility: "",
                ProDivisionID: "",
                ...dataForm,
                //EmployeeID, ProContent, ValidDate, ValidEndDate, ProNotice
                //NumMonth, DisViolationActGroupID, DisViolationActID, ProDisRewardLevelID, ProDisRewardFormID,
                EmployeeID: dataForm && dataForm.Employee && dataForm.Employee.EmployeeID ? dataForm.Employee.EmployeeID : "",
                Language: Config.language || "84",
                DivisionID: Config.getDivisionID(),
                TranMonth: Config.getHRTransMonth(),
                TranYear: Config.getHRTransYear(),
                arrAttachment: JSON.stringify(arrAttachments)
            };
            if (isEdit) {
                params.ProTransID = data && data.ProTransID ? data.ProTransID : "";
            }

            this.setState({ loading: true });
            this.props.w09f2070Actions[isEdit ? "edit" : "save"](params, (error) => {
                this.setState({ loading: false });
                if (error) {
                    let message = Config.lang("DHR_Loi_chua_xac_dinh");
                    switch (error.code) {
                        case "F2000E002":
                            message = "Mode " + Config.lang("DHR_Bat_buoc");
                            break;
                        case "F2000E003":
                            message = "LeaveTypeID " + Config.lang("DHR_Bat_buoc");
                            break;
                        case "F2000E004":
                            message = "IsRegisterType " + Config.lang("DHR_Bat_buoc");
                            break;
                        case "F2000E001":
                            message = Config.lang("DHR_Luu_khong_thanh_cong");
                            break;
                        default:
                            break;
                    }
                    Config.popup.show("INFO", message);
                    return false;
                }
                Config.notify.show('success', Config.lang("DHR_Luu_thanh_cong"), 2000);
                this.props.onClose(true);
            }
            );
        }
    };

    resetForm = () => {
        this.setState({
            dataForm: {
                Employee: null,
                ProDisRewardFormID: "",
                ProDisRewardLevelID: "",
                DisViolationActGroupID: "",
                DisViolationActID: "",
                ProContent: "",
                ProNotice: "",
                ValidDate: moment().format("YYYY-MM-DD"),
                ValidEndDate: null,
                NumMonth: 0
            }
        });
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    loadAttachments = () => {
        const { data } = this.props;
        const param = {
            KeyID: data && data.ProTransID ? data.ProTransID : "",
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };

    render() {
        let { getCboProDisRewardLevel, getCboDisRewardForm, getRewardCaption,
            getCboDisViolationActGroup, getCboDisViolationAct, mode, data
        } = this.props;
        const { uploading, dataForm, error, loading, dataOldAttachments, disabled, dataCboEmployees, employeeLoading } = this.state;

        const employee = Config.getUser({EmployeeID: dataForm?.Employee?.EmployeeID});
        return (
            <>
                <Modal.Content>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Dropdown
                                required
                                error={error && error["EmployeeID"]}
                                valueObjectDefault={
                                    typeof employee === "object" ? employee : null
                                }
                                dataSource={dataCboEmployees.rows}
                                displayExpr={"EmployeeName"}
                                subText={"EmployeeID"}
                                valueExpr={"EmployeeID"}
                                loading={employeeLoading}
                                total={dataCboEmployees.total}
                                skip={this.filterCboEmployees.skip}
                                limit={this.filterCboEmployees.limit}
                                renderSelectedItem={(data) => {
                                    return (
                                        <div className={"display_row align-center"}>
                                            <Avatar
                                                readOnly
                                                width={24}
                                                height={24}
                                                data={data?.data}
                                                src={`${Config.getUserPicture(data?.data?.UserPictureURL)}`}
                                            />
                                            <Typography
                                                hoverTooltip
                                                className={"mgl5"}
                                                lineClamp={1}
                                            >{`${data?.data?.EmployeeID} - ${data?.data?.EmployeeName}`}</Typography>
                                        </div>
                                    );
                                }}
                                value={dataForm?.Employee?.EmployeeID}
                                disabled={mode === "view" || loading || disabled}
                                label={Config.lang("Nhan_vien")}
                                inputProps={{
                                    readOnly: disabled,
                                }}
                                iconExpr={{
                                    key: "UserPictureURL",
                                    prefix: Config.getCDNPath(),
                                }}
                                onChange={(e) => this.handleChange("EmployeeID", e)}
                                onInput={(e) => {
                                    this.filterCboEmployees.skip = 0;
                                    this.filterCboEmployees.strSearch = e.target.value;
                                    this.loadCboEmployees(true);
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboEmployees.skip = e.skip;
                                    this.filterCboEmployees.limit = e.limit;
                                    this.loadCboEmployees();
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <FormControl variant={"standard"}
                                margin={"normal"}
                                fullWidth={true}>
                                <InputLabel
                                    shrink={true}
                                    required={true}
                                >
                                    {Config.lang("DHR_Thoi_gian_hieu_luc")}
                                </InputLabel>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <div className={"display_row align-right"}>
                                            <FormLabel component={"label"} className={"mgb15 mgr10"}>{Config.lang("DHR_Tu")}</FormLabel>
                                            <DateBoxPicker
                                                placeholder={Config.lang("DHR_Chon_ngay")}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                max={dataForm.ValidEndDate ? dataForm.ValidEndDate : null}
                                                stylingMode={"underlined"}
                                                shrink={true}
                                                value={dataForm.ValidDate}
                                                onValueChanged={e => this.handleChange("ValidDate", e)}
                                                margin={"normal"}
                                                disabled={disabled || loading}
                                                error={error && error["ValidDate"]}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <div className={"display_row align-right"}>
                                            <FormLabel component={"label"} className={"mgb15 mgr10"}>{Config.lang("DHR_Den")}</FormLabel>
                                            <DateBoxPicker
                                                placeholder={Config.lang("DHR_Chon_ngay")}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                min={dataForm.ValidDate ? dataForm.ValidDate : null}
                                                width={"100%"}
                                                stylingMode={"underlined"}
                                                value={dataForm.ValidEndDate}
                                                onValueChanged={e => this.handleChange("ValidEndDate", e)}
                                                margin={"normal"}
                                                disabled={disabled || loading}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormControl>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <TextField
                                error={error && error["NumMonth"]}
                                label={Config.lang('DHR_So_thang')}
                                variant={"standard"}
                                margin={"normal"}
                                type={'number'}
                                disabled={disabled || loading}
                                value={String(dataForm.NumMonth)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                inputProps={{ min: 0 }}
                                onChange={(e) => this.handleChange('NumMonth', e)}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Combo
                                error={error && error["DisViolationActGroupID"]}
                                dataSource={getCboDisViolationActGroup}
                                displayExpr={"DVAGroupName"}
                                valueExpr={"DisViolationActGroupID"}
                                subTextExpr={"DisViolationActGroupID"}
                                stylingMode={"underlined"}
                                disabled={disabled || loading}
                                searchEnabled={true}
                                shrink={true}
                                value={dataForm.DisViolationActGroupID}
                                label={Config.lang("DHR_Nhom_hanh_vi_vi_pham_ky_luat")}
                                onValueChanged={e => this.handleChange("DisViolationActGroupID", e)}
                                margin={"normal"}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Combo
                                error={error && error["DisViolationActID"]}
                                dataSource={getCboDisViolationAct}
                                displayExpr={"DVAName"}
                                valueExpr={"DisViolationActID"}
                                subTextExpr={"DisViolationActID"}
                                stylingMode={"underlined"}
                                disabled={disabled || loading}
                                searchEnabled={true}
                                shrink={true}
                                value={dataForm.DisViolationActID}
                                label={Config.lang("DHR_Hanh_vi_vi_pham_ky_luat")}
                                onValueChanged={e =>
                                    this.handleChange("DisViolationActID", e)
                                }
                                margin={"normal"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Combo
                                error={error && error["ProDisRewardFormID"]}
                                dataSource={getCboDisRewardForm}
                                displayExpr={"ProDisRewardFormName"}
                                valueExpr={"ProDisRewardFormID"}
                                subTextExpr={"ProDisRewardFormID"}
                                stylingMode={"underlined"}
                                disabled={disabled || loading}
                                searchEnabled={true}
                                shrink={true}
                                value={dataForm.ProDisRewardFormID}
                                label={Config.lang("DHR_Hinh_thuc_ky_luat")}
                                onValueChanged={e => this.handleChange("ProDisRewardFormID", e)}
                                margin={"normal"}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Combo
                                error={error && error["ProDisRewardLevelID"]}
                                dataSource={getCboProDisRewardLevel}
                                displayExpr={"ProDisRewardLevelName"}
                                valueExpr={"ProDisRewardLevelID"}
                                subTextExpr={"ProDisRewardLevelID"}
                                stylingMode={"underlined"}
                                disabled={disabled || loading}
                                searchEnabled={true}
                                shrink={true}
                                value={dataForm.ProDisRewardLevelID}
                                label={Config.lang("DHR_Cap_ky_luat")}
                                onValueChanged={e => this.handleChange("ProDisRewardLevelID", e)}
                                margin={"normal"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                error={error && error["ProContent"]}
                                label={Config.lang("DHR_Noi_dung")}
                                variant={"standard"}
                                margin={"normal"}
                                value={dataForm.ProContent}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                disabled={disabled || loading}
                                helperText={this.state.helperTextQuantity}
                                onChange={e => this.handleChange("ProContent", e)}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                label={Config.lang("DHR_Ghi_chu")}
                                variant={"standard"}
                                margin={"normal"}
                                value={dataForm.ProNotice}
                                disabled={disabled || loading}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                helperText={this.state.helperTextQuantity}
                                onChange={e => this.handleChange("ProNotice", e)}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <FormGroup>
                        <Row>
                            {getRewardCaption && getRewardCaption.map((item, i) => {
                                if (item.Disabled === 0) {
                                    return <Col key={i} xs={12} sm={12} md={6} lg={6}>
                                        <NumberFormat
                                            label={item.Short}
                                            customInput={TextField}
                                            thousandSeparator={true}
                                            value={String(dataForm[item.Code]) || ""}
                                            onValueChange={e => this.handleChange(item.Code, e)}
                                            fullWidth
                                            disabled={loading || disabled}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            variant={"standard"}
                                            margin={"normal"}
                                            helperText={this.state.helperTextQuantity}
                                            InputProps={{ inputProps: { min: 0 } }}
                                        />
                                    </Col>
                                } else {
                                    return null;
                                }
                            })}
                        </Row>
                    </FormGroup>
                    <FormGroup style={{ marginBottom: 34 }}>
                        <Attachments
                            error={error && error["Attachment"]}
                            ref={ref => this.attRef = ref}
                            showButton={true}
                            files={dataOldAttachments}
                            disabled={disabled || loading || uploading}
                            maxLength={5}
                            uploading={loading}
                            onUploading={this.onUploading}
                            onChanged={this.onChangeAttachments}
                        />
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <div>
                        <IconButton aria-label="view" disabled={loading || uploading} onClick={this.onAttachment}>
                            <AttachFileIcon />
                        </IconButton>
                    </div>
                    <div>
                        <ButtonGeneral
                            name={Config.lang("DHR_Luu")}
                            typeButton={"save"}
                            disabled={Number(data?.OnlyWatch) === 1 || loading || disabled || uploading}
                            style={{ textTransform: "uppercase" }}
                            size={"large"}
                            onClick={this.onSave}
                        />
                    </div>

                </Modal.Actions>
            </>
        );
    }
}

W09F2070Popup.propTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,

    onClose: PropTypes.func
};

export default compose(
    connect(
        state => ({
            getCboEmployees: state.general.getCboEmployees,
            getLeaveTypes: state.W09F2070.getLeaveTypes,
            loadForm: state.W09F2070.loadForm,
            getCboDisRewardForm: state.W09F2070.getCboDisRewardForm,
            getCboProDisRewardLevel: state.W09F2070.getCboProDisRewardLevel,
            getCboDisViolationActGroup: state.W09F2070.getCboDisViolationActGroup,
            getCboDisViolationAct: state.W09F2070.getCboDisViolationAct,
            getRewardCaption: state.W09F2070.getRewardCaption,
            getForm: state.W09F2070.getForm,
            getRequiredFields: state.general.getRequiredFields,
            getAttachmentsByTransID: state.general.getAttachmentsByTransID
        }),
        dispatch => ({
            w09f2070Actions: bindActionCreators(W09F2070Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch)
        })
    ),
    withStyles(styles)
)(W09F2070Popup);
