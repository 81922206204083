/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 13/02/2020
 * @Example
 */

import React from "react";
import {FormGroup, Col, Row} from "react-bootstrap";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Approvals from "../../../approvals/approvals";
import LabelText from "../../../common/label-text/label-text";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as W09F2071Actions from "../../../../redux/W0X/W09F2071/W09F2071_actions";
import {Input, withStyles} from "@material-ui/core";
import InlineSVG from "react-inlinesvg";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import moment from "moment";
import * as generalActions from "../../../../redux/general/general_actions";
import {Avatar} from "diginet-core-ui/components";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
    },
    label: {
        fontSize: "1rem",
        fontWeight: 200,
        color:"#757575"
    },
    divText : {
        // fontSize: "1rem",
        fontWeight: 500,
        // color:"#575757"
    }
};

class W09F2071 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataForm: {},
            VoucherID: "",
            loading: false
        }
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2071", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadRewardCaption = () => {
        const params = {
            Language: Config.language
        };

        this.props.W09F2071Actions.loadRewardCaption(params, (errors) => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        })
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadRewardCaption();
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W09F2071",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.setState({loading: true})
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            this.setState({loading: false})
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb (data);
            }
        });
    };

    renderFormInfo = (data) => {
        const {classes} = this.props;
        let {getRewardCaption} = this.props;
        const ValidDate = Config.helpers.getObjectValue(data,"ValidDate","");
        const ValidEndDate = Config.helpers.getObjectValue(data,"ValidEndDate","");

        return (
            <>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={8} lg={8} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Nhan_vien")}
                                value={() => {
                                    return (
                                        <div className={"display_row align-center pdb5"}
                                             style={{width: "100%", height: 37}}>
                                            <Avatar width={32}
                                                    height={32}
                                                    readOnly
                                                    className={'mgr1x'}
                                                    src={Config.getCDNPath(data.UserPictureURL)}/>
                                            <Input
                                                color={"primary"}
                                                readOnly={true}
                                                value={Config.helpers.getObjectValue(data, "EmployeeName", "")}
                                                disableUnderline={true}
                                            />
                                        </div>
                                    );
                                }}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ma_nhan_vien")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "EmployeeID", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={8} lg={8} className={"mgb15"}>
                            <div className={classes.label}>
                                {Config.lang("DHR_Thoi_gian_hieu_luc")}
                            </div>
                            <div className="display_row">
                                <div className="display_row align-center">
                                    <div className={classes.divText + " pdr2x pdl2x"}>
                                        {Config.lang("DHR_Tu")}
                                    </div>
                                    <LabelText
                                        allowPadding={true}
                                        fullWidth={true}
                                        startAdornment={<div className={"pdr5"}><InlineSVG src={require('../../../../assets/images/icon-calendar.svg')}/></div>}
                                        value={ValidDate && moment(ValidDate).format("DD/MM/YYYY")}
                                    />
                                </div>
                                <div className="display_row align-center">
                                    <div className={classes.divText + " pdr10"} style={{paddingLeft: "10px"}}>
                                        {Config.lang("DHR_Den").toLowerCase()}
                                    </div>
                                    <LabelText
                                        allowPadding={true}
                                        fullWidth={true}
                                        startAdornment={ <div className={"pdr5"}><InlineSVG src={require('../../../../assets/images/icon-calendar.svg')}/></div>}
                                        value={ValidEndDate && moment(ValidEndDate).format("DD/MM/YYYY")}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} style={{paddingTop: 6}} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_So_thang")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "NumMonth", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Nhom_hanh_vi_vi_pham_ky_luat")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "DisViolationActGroupName", "")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Hanh_vi_vi_pham_ky_luat")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "DisViolationActName", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Hinh_thuc_ky_luat")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "ProDisRewardFormName", "")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Cap_ky_luat")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "ProDisRewardFormName", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Noi_dung")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "ProContent", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ghi_chu")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "ProNotice","")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        {getRewardCaption &&
                        getRewardCaption.map((items, index) => {
                            if(getRewardCaption[index].Disabled) return null;
                            const vl = Config.helpers.getObjectValue(data, getRewardCaption[index].Code, "");
                            return(
                                <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                    <Row style={{display: "flex", alignItems: "center"}}>
                                        <Col xs={4} sm={4} md={4} lg={4} className={classes.label}>
                                            {items.Short}
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={8}>
                                            <LabelText
                                                allowPadding={true}
                                                value={vl ? parseInt(vl).toLocaleString() : ""}
                                                inputProps={{
                                                    className: "text-center"
                                                }}
                                                fullWidth={true}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })}
                    </Row>
                </FormGroup>
            </>

        )
    };

    render() {
        const {iPermission, dataForm, VoucherID, loading} = this.state;
        if (!iPermission) return null;

        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Duyet_ky_luat")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                allowHistoryApproval
                                loading={loading}
                                parentProps={this.props}
                                FormID={"W09F2071"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                selectedRowKey={VoucherID}
                                singleClick
                                formInfoRender={this.renderFormInfo}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        );
    }

}

export default compose(connect((state) => ({
        getRewardCaption: state.W09F2071.getRewardCaption
    }),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        W09F2071Actions: bindActionCreators(W09F2071Actions, dispatch),
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
    })
), withStyles(styles, {withTheme: true}))(W09F2071);
