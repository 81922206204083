/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/12/2020
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup} from "react-bootstrap";
import {MForm, TextField} from "../../../common/form-material";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2080Actions from "../../../../redux/W0X/W09F2080/W09F2080_actions";
import moment from "moment";
import Modal from "../../../common/modal/modal";
import CDN from "../../../CDN";
import NumberFormat from "react-number-format";
import * as W09F2150Actions from "../../../../redux/W0X/W09F2150/W09F2150_actions";
import {
    Button,
    Dropdown,
    TextInput,
    Checkbox,
    DatePicker,
    Avatar,
    Typography,
    Attachment,
    TabContainer,
    TabHeader,
    TabItem,
    TabPanel,
    Accordion,
    AccordionDetails,
    AccordionSummary
} from "diginet-core-ui/components";

class W09F2080Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            iPermissions: {},
            showW09F2070Popup: false,
            tabKey: 0,
            disabled: false,
            error: {},
            loading: false,
            uploading: false,
            dataTeam: [],
            dataFormSalary: {},
            dataColumnSalary: [],
            dataOldAttachments: [],
            dataOldForm: {
                DepartmentID: "",
                DutyName: "",
                DutyID: "",
                TeamID: "",
                WorkID: "",
                ProjectID: "",
                DepartmentName: "",
                TeamName: "",
                WorkName: "",
                ProjectName: "",
                BASE01: 0,
                BASE02: 0,
                BASE03: 0,
                BASE04: 0,
            },
            dataForm: {
                Employee: null,
                NewDutyID: "",
                NewDepartmentID: "",
                NewTeamID: "",
                NewWorkID: "",
                NewProject: null,
                NewProjectName: "",
                ProContent: "",
                ProNotice: "",
                ValidDate: moment().format("YYYY-MM-DD"),
                NewBASE01: 0,
                NewBASE02: 0,
                NewBASE03: 0,
                NewBASE04: 0,
                IsSalaryAdjustment: 0,
                NewDirectManagerID: "",
                IsDutyAdjustment: 0,
            },
            dataCboProjects: {
                rows: [],
                total: 0
            },
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            dataCboDirectManagers: [],
            dataDefaultEmployee: {},
            cboDirectManagersLoading: false,
            cboCboEmployeesLoading: false,
            projectsLoading: false,
        };
        this.attachments = [];
        this.deletedFile = [];
        this.filterCboProject = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboEmployees = {
            search: "",
            skip: 0,
            limit: 50
        };
        this.isChangeForm = false;
        this.dataCboDirectManagers = [];
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission(["W09F5650", "W09F5602", "W09F2150"], (arrPer) => {
            const permissions = {"W09F5650": 0, "W09F5602": 0, "W09F2150": 0};
            if (Array.isArray(arrPer)) {
                arrPer.forEach(per => {
                    if (per?.FormID && per?.IsPermission) {
                        permissions[per.FormID] = per.IsPermission;
                    }
                });
            }
            this.setState({iPermissions: permissions});
        }, true);
    };

    loadCboProjects = (isReset) => {
        const param = {
            HostID: "",
            FormID: "W09F2080",
            Language: Config.language || "84",
            skip: this.filterCboProject.skip,
            limit: this.filterCboProject.limit,
            search: this.filterCboProject.strSearch
        };
        this.setState({ projectsLoading: true});
        this.props.generalActions.getCboProjects(param, (error, data) => {
            this.setState({ projectsLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    },
                });
            }
        });
    };

    loadCboDepartment = () => {
        const param = {
            HostID: "",
            FormID: "W09F2080",
            DivisionID: Config.getDivisionID(),
            Language: Config.language || "84"
        };

        this.props.generalActions.getCboDepartments(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboTeam = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
            CompanyID: "",
            HostID: "",
            FormID: "W09F2080",
            Language: Config.language || "84"
        };
        this.props.generalActions.getCboTeam(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({dataTeam: data});
            }
        });
    };

    loadCboWorks = () => {
        const params = {
            Language: Config.language || "84"
        };
        this.props.generalActions.getCboWorks(params, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadForm = (EmployeeID) => {
        const {getCaption} = this.props;
        const param = {
            EmployeeID: EmployeeID || Config.getHREmployeeID()
        };
        this.setState({loading: true});
        this.props.w09f2080Actions.loadForm(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const dataOldForm= {
                        DutyName: data?.DutyName || "",
                        DutyID: data?.DutyID || "",
                        DepartmentID: data.DepartmentID ? data.DepartmentID : "",
                        TeamID: data.TeamID ? data.TeamID : "",
                        WorkID: data.WorkID ? data.WorkID : "",
                        ProjectID: data.ProjectID ? data.ProjectID : "",
                        DepartmentName: data.DepartmentName ? data.DepartmentName : "",
                        TeamName: data.TeamName ? data.TeamName : "",
                        WorkName: data.WorkName ? data.WorkName : "",
                        ProjectName: data.ProjectName ? data.ProjectName : "",
                        BASE01: data.BASE01 || data.BASE01 === 0 ? Number(data.BASE01) : 0,
                        BASE02: data.BASE02 || data.BASE02 === 0 ? Number(data.BASE02) : 0,
                        BASE03:data.BASE03 || data.BASE03 === 0 ? Number(data.BASE03) : 0,
                        BASE04: data.BASE04 || data.BASE04 === 0 ? Number(data.BASE04) : 0,
                        DirectManagerID: data?.DirectManagerID || "",
                        DirectManagerName: data?.DirectManagerName || "",
                };
                const dataForm= {
                    ...this.state.dataForm,
                    NewDutyID: data?.NewDutyID || "",
                    NewDepartmentID: data.DepartmentID ? data.DepartmentID : "",
                    NewTeamID: data.TeamID ? data.TeamID : "",
                    NewWorkID: data.WorkID ? data.WorkID : "",
                    NewProject: data?.ProjectID || "",
                    NewProjectName: data?.ProjectName || "",
                    IsSalaryAdjustment: data.IsSalaryAdjustment ? data.IsSalaryAdjustment : 0,
                    NewBASE01: data.NewBASE01 ? data.NewBASE01 : 0,
                    NewBASE02: data.NewBASE02 ? data.NewBASE02 : 0,
                    NewBASE03: data.NewBASE03 ? data.NewBASE03 : 0,
                    NewBASE04: data.NewBASE04 ? data.NewBASE04 : 0,
                    NewDirectManagerID: data?.DirectManagerID || "",
                    DirectManagerName: data?.DirectManagerName || "",
                };
                getCaption.forEach(field => {
                    if (!field.Disabled) {
                        let no = field && field.Code ? field.Code.slice(-2) : "";
                        dataForm["NewSalCoefficient" + no]  = data["NewSalCoefficient" + no];
                        dataOldForm["SalCoefficient" + no]  = data["SalCoefficient" + no];
                    }
                });
                this.setState({
                    dataOldForm,
                    dataForm
                });
            }
        });
    };

    loadRequiredFields = () => {
        this.setState({loading: true});
        this.props.w09f2080Actions.getRequiredFields((error) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadDynamicColumnSalary = () => {
        const params = {
            Language: Config.language || 84
        };
        this.setState({loading: true});
        this.props.w09f2080Actions.getDynamicColumnSalary(params,(error,data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(data) {
                this.setState({dataColumnSalary : data});
            }
        });
    };

    loadCboReasons = () => {
        this.setState({loading: true});
        this.props.w09f2080Actions.getCboReasons({TypeID: 3},(error) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboEmployees = (isReset) => {
        const params = {
            Type: "EmployeeID",
            FormID: "W09F2080",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.search
        };
        this.setState({cboCboEmployeesLoading: true});
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setState({cboCboEmployeesLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data?.rows || data;
                const total = data?.total || data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboDirectManagers = () => {
        const params = {
            FormID: "W09F2080",
        };
        this.setState({cboDirectManagersLoading: true});
        this.props.w09f2080Actions.getCboDirectManagers(params, (error, data) => {
            this.setState({cboDirectManagersLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.dataCboDirectManagers = data?.[0] || [];
            this.setState({
                dataCboDirectManagers: data?.[0] || []
            })
        });
    };

    setDataCboDirectManager = (employeeId = "") => {
        let dtCboDirectManagers;
        if(employeeId) {
            dtCboDirectManagers = this.dataCboDirectManagers.filter(i => i.EmployeeID !== employeeId);
        } else {
            dtCboDirectManagers = this.dataCboDirectManagers;
        }
        this.setState({dataCboDirectManagers: dtCboDirectManagers})
    };
    loadCboPosition = () => {
        this.setState({loading: true});
        this.props.generalActions.getCboDuty2(error => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        })
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        const {mode} = this.props;
        if(!this.isChangeForm){
            this.isChangeForm = true;
        }
        switch (key) {
            case "EmployeeID":
                this._setDataForm("Employee", e.value, () => {
                    const EmployeeID =  e?.value || "";
                    if (EmployeeID && mode === 'add') {
                        this.loadForm(EmployeeID);
                    }
                });
                this.setDataCboDirectManager(e.value);
                break;
            case "NewDutyID":
                this._setDataForm(key, e.value);
                break;
            case "ValidDate":
                this._setDataForm(key, e.value);
                break;
            case "NewDepartmentID":
                const {getCboTeam} = this.props;
                const departmentID = e.value;
                this._setDataForm(key, departmentID, () => {
                    if (departmentID && getCboTeam && getCboTeam.length > 0) {
                        this.setState({
                            dataTeam: getCboTeam.filter(team => team.DepartmentID === departmentID),
                            dataForm: {
                                ...this.state.dataForm,
                                NewTeamID: ""
                            }
                        });
                    }
                });
                break;
            case "NewTeamID":
                this._setDataForm(key, e.value);
                break;
            case "ProjectID":
                this._setDataForm("NewProject", e.value);
                break;
            case "NewWorkID":
                this._setDataForm(key, e.value);
                break;
            case "ProContent":
                this._setDataForm(key, e?.value || e?.target?.value || "");
                break;
            case "ProNotice":
                this._setDataForm(key, e.target.value);
                break;
            case "NewDirectManagerID":
                this._setDataForm(key, e.value);
                break;
            default:
                let value = e.value ? e.value : (e.target && e.target.value ? e.target.value : "");
                this._setDataForm(key, value);
                break;
        }
    };

    componentDidMount = async () => {
        await this.loadPermission();
        const {mode, data} = this.props;
        this.loadCboProjects();
        this.loadCboDepartment();
        this.loadCboTeam();
        this.loadCboWorks();
        this.loadRequiredFields();
        this.loadDynamicColumnSalary();
        this.loadCaption();
        this.loadCboReasons();
        this.loadCboDirectManagers();
        this.loadCboPosition();
        const _employeeID = Config.getHREmployeeID();
        if (mode === 'add') {
            this.loadCboEmployees();
            const {iPermissions} = this.state;
            if (iPermissions?.W09F5650 <= 2) {
                const user = Config.getUser({EmployeeID: _employeeID});
                this._setDataForm('EmployeeID', user?.EmployeeID || "");
                this.loadForm(user?.EmployeeID || "");
            }
        } else {
            this.loadDataPopup();
            this.loadAttachments();
            if (data && (Number(data.AppStatusID) !== 0)) {
                this.setState({disabled: true});
            }
        }
    };

    loadDataPopup = () => {
        const {data} = this.props;
        const param = {
            ProTransID: data && data.ProTransID ? data.ProTransID : ""
        };
        this.setState({loading: true});
        this.props.w09f2080Actions.loadFormPopup(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this._initDataForm(data);
            }
        });
    };

    _initDataForm = (data) => {
        const {getCaption} = this.props;
        const EmployeeID = data && data.EmployeeID ? data.EmployeeID : "";
        const user = Config.getUser({EmployeeID: EmployeeID});
        if (user?.UserPictureURL) {
            if (user.UserPictureURL.indexOf('http') < 0) {
                user.UserPictureURL = Config.getCDNPath() + user.UserPictureURL;
            }
        }
        const Employee = {
            EmployeeID: EmployeeID,
            EmployeeName: data && data.EmployeeName ? data.EmployeeName : (user ? user.EmployeeName : ""),
            UserPictureURL: data && data.UserPictureURL ? data.UserPictureURL : (user ? user.UserPictureURL : ""),
        };
        let dataOldForm = {
            DutyName: data?.DutyName || "",
            DutyID: data?.DutyID || "",
            DepartmentID: data && data.DepartmentID ? data.DepartmentID : "",
            TeamID: data && data.TeamID ? data.TeamID : "",
            WorkID: data && data.WorkID ? data.WorkID : "",
            ProjectID: data && data.ProjectID ? data.ProjectID : "",
            DepartmentName: data && data.DepartmentName ? data.DepartmentName : "",
            TeamName: data && data.TeamName ? data.TeamName : "",
            WorkName: data && data.WorkName ? data.WorkName : "",
            ProjectName: data && data.ProjectName ? data.ProjectName : "",
            BASE01: data.BASE01 || data.BASE01 === 0 ? Number(data.BASE01) : 0,
            BASE02: data.BASE02 || data.BASE02 === 0 ? Number(data.BASE02) : 0,
            BASE03:data.BASE03 || data.BASE03 === 0 ? Number(data.BASE03) : 0,
            BASE04: data.BASE04 || data.BASE04 === 0 ? Number(data.BASE04) : 0,
            DirectManagerID: data?.DirectManagerID || "",
            DirectManagerName: data?.DirectManagerName || "",
        };
        let dataForm = {
            Employee: EmployeeID,
            DutyName: data?.DutyName || "",
            NewDutyID: data?.NewDutyID || "",
            NewDepartmentID: data && data.NewDepartmentID ? data.NewDepartmentID : "",
            NewTeamID: data && data.NewTeamID ? data.NewTeamID : "",
            NewWorkID: data && data.NewWorkID ? data.NewWorkID : "",
            NewProject:  data?.NewProjectID  || "",
            NewProjectName: data?.NewProjectName || "",
            ProContent: data && data.ProContent ? data.ProContent : "",
            ProNotice: data && data.ProNotice ? data.ProNotice : "",
            ValidDate: data && data.ValidDate ? moment.utc(data.ValidDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            IsSalaryAdjustment: data.IsSalaryAdjustment ? Number(data.IsSalaryAdjustment) : 0,
            IsDutyAdjustment: Number(!!data?.IsDutyAdjustment),
            NewBASE01: data.NewBASE01 || data.NewBASE01 === 0 ? Number(data.NewBASE01)  : 0,
            NewBASE02: data.NewBASE02 || data.NewBASE02 === 0 ? Number(data.NewBASE02) : 0,
            NewBASE03: data.NewBASE03 || data.NewBASE03 === 0 ? Number(data.NewBASE03) : 0,
            NewBASE04: data.NewBASE04 || data.NewBASE04 === 0 ? Number(data.NewBASE04) : 0,
            NewDirectManagerID: data?.NewDirectManagerID || "",

        };
        getCaption.forEach(field => {
            if (!field.Disabled) {
                let no = field && field.Code ? field.Code.slice(-2) : "";
                dataForm["NewSalCoefficient" + no]  = data["NewSalCoefficient" + no];
                dataOldForm["SalCoefficient" + no]  = data["SalCoefficient" + no];
            }
        });

        this.setState({
            dataOldForm,
            dataForm: {...this.state.dataForm, ...dataForm},
            dataDefaultEmployee: Employee,
        });
    };

    _setDataForm = (key, value, cb) => {
        if (!key) return false;
        this.setState({
            dataForm: {
                ...this.state.dataForm,
                [key]: value
            }
        }, () => {
            if (cb) cb();
        });
    };

    setStateErrorText(objValue, key) {
        const stateError = key ? key : "error";
        this.setState({
            [stateError]: {
                ...(this.state[stateError] ? this.state[stateError] : {}),
                ...objValue
            }
        });
        return Object.keys(objValue).length !== 0;
    }

    _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    onSave = async () => {
        const {mode, data, getRequiredFields, getCaption, getCboReasons} = this.props;
        const isEdit = mode === "view";
        const {dataForm, dataOldForm} = this.state;
        const listDataForm = Object.keys(dataForm);
        let dataRequired = ["EmployeeID", "ValidDate", "NewDepartmentID"];
        getRequiredFields && getRequiredFields.forEach((field) => {
            if (field.ValidMode && field.ValidMode === "O"
                && field.SqlFieldName && listDataForm.indexOf(field.SqlFieldName) > -1
                && dataRequired.indexOf(field.SqlFieldName) < 0
            ) {
                dataRequired.push(field.SqlFieldName);
            }
        });
        //validate form..
        const validateRules = [];
        dataRequired && dataRequired.forEach((item) => {
            let rule;
            switch (item) {
                case "EmployeeID":
                    rule = {
                        name: item,
                        rules: ["isRequired"],
                        value: dataForm?.Employee ||  ""
                    };
                    break;
                case "ProjectID":
                    rule = {
                        name: item,
                        rules: ["isRequired"],
                        value: dataForm?.NewProject  || ""
                    };
                    break;
                default:
                    rule = {
                        name: item,
                        rules: ["isRequired"],
                        value: dataForm[item]
                    };
                    break;
            }
            validateRules.push(rule);
        });
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            const dataResUploadFile = await this._uploadFile(this.attachments, true);
            const arrAttachments = this._getAttachments(dataResUploadFile);
            const dtReason = getCboReasons.find(i => i.ReasonID === dataForm.ProContent);
            let params = {
                DivisionID: Config.getDivisionID(),
                HostID: "",
                Language: Config.language || "84",
                EmployeeID: dataForm?.Employee || "",
                NewDutyID: dataForm?.NewDutyID,
                DutyID: dataOldForm?.DutyID || "",
                IsDutyAdjustment: Number(!!dataForm?.IsDutyAdjustment),
                ValidDate: dataForm && dataForm.ValidDate ? dataForm.ValidDate : "",
                ProContent: dtReason?.ProContent || dataForm.ProContent || "",
                ProNotice: dataForm && dataForm.ProNotice ? dataForm.ProNotice : "",

                TempDepartmentID: dataOldForm && dataOldForm.DepartmentID ? dataOldForm.DepartmentID : "",
                TempTeamID: dataOldForm && dataOldForm.TeamID ? dataOldForm.TeamID : "",
                TempWorkID: dataOldForm && dataOldForm.WorkID ? dataOldForm.WorkID : "",
                TempProjectID: dataOldForm && dataOldForm.ProjectID ? dataOldForm.ProjectID : "",

                ProDepartmentID: dataForm && dataForm.NewDepartmentID ? dataForm.NewDepartmentID : "",
                ProTeamID: dataForm && dataForm.NewTeamID ? dataForm.NewTeamID : "",
                ProWorkID: dataForm && dataForm.NewWorkID ? dataForm.NewWorkID : "",
                ProProjectID: dataForm?.NewProject || "",
                NewDirectManagerID: dataForm?.NewDirectManagerID || "",
                DirectManagerID: dataForm?.DirectManagerID || dataOldForm?.DirectManagerID || "",
                TranMonth: Config.getHRTransMonth(),
                TranYear: Config.getHRTransYear(),
                IsSalaryAdjustment: dataForm && dataForm.IsSalaryAdjustment ? Number(dataForm.IsSalaryAdjustment) : 0,
                ProBaseSalary01: Number(dataForm.IsSalaryAdjustment) === 0 ? 0 : (dataForm && dataForm.NewBASE01 ? Number(dataForm.NewBASE01) : 0),
                ProBaseSalary02: Number(dataForm.IsSalaryAdjustment) === 0 ? 0 : (dataForm && dataForm.NewBASE02 ? Number(dataForm.NewBASE02) : 0),
                ProBaseSalary03: Number(dataForm.IsSalaryAdjustment) === 0 ? 0 : (dataForm && dataForm.NewBASE03 ? Number(dataForm.NewBASE03) : 0),
                ProBaseSalary04: Number(dataForm.IsSalaryAdjustment) === 0 ? 0 : (dataForm && dataForm.NewBASE04 ? Number(dataForm.NewBASE04) : 0),
                arrAttachment: JSON.stringify(arrAttachments),
            };
            getCaption.forEach(field => {
                if (!field.Disabled) {
                    let no = field && field.Code ? field.Code.slice(-2) : "";
                    params["NewSalCoefficient" + no]  = Number(dataForm.IsSalaryAdjustment) === 0 ? 0 : (dataForm?.["NewSalCoefficient" + no] ? Number(dataForm["NewSalCoefficient" + no] || 0) : 0);
                }
            });
            if (isEdit) {
                params.ProTransID = data && data.ProTransID ? data.ProTransID : "";
                delete params.DirectManagerID;
            }

            this.setState({loading: true});
            this.props.w09f2080Actions[isEdit ? "update" : "save"](params, (error, data) => {
                this.setState({loading: false});
                if (error) {
                    error.customMessage = {
                        "F2000E002" : "Mode " + Config.lang("Bat_buoc"),
                        "F2000E003" : "LeaveTypeID " + Config.lang("Bat_buoc"),
                        "F2000E004" : "IsRegisterType " + Config.lang("Bat_buoc"),
                        "F2000E001" : Config.lang("Luu_khong_thanh_cong"),
                    };
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if (data) {
                    if (data.Status === 1) {
                        Config.popup.show('INFO', data.Message);
                        return false;
                    } else if (data.Status === 0) {
                        this._removeCDN();
                        this.props.onClose(true);
                        Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    } else {
                        Config.popup.show('INFO', Config.lang("Luu_khong_thanh_cong"));
                        return false;
                    }
                }
            });
        }

    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    handleTabChange = (key) => {
        this.setState({tabKey: key});
    };

    resetForm = () => {
        this.setState({
            dataOldForm: {
                DutyID: "",
                DutyName: "",
                DepartmentID: "",
                TeamID: "",
                WorkID: "",
                ProjectID: "",
                DepartmentName: "",
                TeamName: "",
                WorkName: "",
                ProjectName: "",
                BASE01: 0,
                BASE02: 0,
                BASE03: 0,
                BASE04: 0,

            },
            dataForm: {
                Employee: null,
                NewDutyID: "",
                NewDepartmentID: "",
                NewTeamID: "",
                NewWorkID: "",
                NewProject: null,
                NewProjectName: "",
                ProContent: "",
                ProNotice: "",
                ValidDate: moment().format("YYYY-MM-DD"),
                IsSalaryAdjustment: 0,
                NewBASE01: 0,
                NewBASE02: 0,
                NewBASE03: 0,
                NewBASE04: 0,
            }
        });
    };

    onClose = () => {
        const {onClose} = this.props;
        this.resetForm();
        if (onClose) onClose();
    };

    _getAttachments = (file) => {
        const dataFile        = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        const {dataOldAttachments} = this.state;
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt : att.fileName ? att.fileName.split('.').pop() : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    loadAttachments = () => {
        const {data} = this.props;
        const param = {
            KeyID: data && data.ProTransID ? data.ProTransID : "",
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    onUploading = (value) => {
        this.setState({uploading: value});
    };

    onChangeAttachments = (e) => {
        this.attachments = e?.allNewAttached || [];
        if (e?.removedAttached?.length > 0) {
            this.deletedFile = [...e.removedAttached];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState((prevState) => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
      };

    handleChangeDataSalary = (index,data) => {
        this._setDataForm(index, data.value);
    };

    onChangeChkSalary = () => {
        const { dataForm, dataOldForm, dataColumnSalary } = this.state;
        this.setState({
            dataForm: {
                ...this.state.dataForm,
                IsSalaryAdjustment: !dataForm.IsSalaryAdjustment,
            }
        }, () => {
            const {IsSalaryAdjustment} = this.state.dataForm;
            if (IsSalaryAdjustment) {
                let obj = {};
                dataColumnSalary && dataColumnSalary.forEach(d => {
                    let key              = d.Code.slice(-2);
                    obj["NewBASE" + key] = dataOldForm["BASE" + key];
                });
                this.setState({
                    dataForm: {
                        ...this.state.dataForm,
                        ...obj
                    }
                });
            }
        });
    };

    onChangeDutyAdjustment = () => {
        const {dataForm} = this.state;
        this.setState({dataForm: {...dataForm, IsDutyAdjustment: +!dataForm.IsDutyAdjustment}})
    };

    loadCaption = () => {
        this.props.w09F2150Actions.loadCaption({Language: Config.language || "84" },(error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    render() {
        let {getCboDepartments, getCboWorks, mode, getCaption, getCboReasons, getCboPosition, iPermissionW09F2080
        } = this.props;
        const {iPermissions, uploading, dataForm, dataOldForm, error, loading, dataOldAttachments, disabled, tabKey,
            dataTeam, dataCboProjects, projectsLoading , cboDirectManagersLoading, cboCboEmployeesLoading,
            dataColumnSalary, dataCboEmployees, dataCboDirectManagers, dataDefaultEmployee,
        } = this.state;
        return (
            <>
                <Modal.Content>
                    <TabContainer
                        value={tabKey}
                    >
                        <TabHeader className={"mgb10"}>
                                <TabItem
                                    index={0}
                                    label={Config.lang("Thong_tin_chinh")}
                                    onClick={()=>this.handleTabChange(0)}
                                />
                                <TabItem
                                    index={1}
                                    label={Config.lang("Mo_rong")}
                                    onClick={()=>this.handleTabChange(1)}
                                />
                        </TabHeader>
                        <TabPanel index={0}>
                            <React.Fragment>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Dropdown
                                            valueObjectDefault={ mode !== "add" ? {
                                                EmployeeID: dataDefaultEmployee?.EmployeeID || "",
                                                EmployeeName: dataDefaultEmployee?.EmployeeName || "",
                                                UserPictureURL: dataDefaultEmployee?.UserPictureURL || "",
                                            } : null}
                                            allowSearch
                                            error={error ? error["EmployeeID"] : ""}
                                            dataSource={dataCboEmployees?.rows || []}
                                            total={dataCboEmployees?.total ?? 0}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            loading={cboCboEmployeesLoading}
                                            value={dataForm.Employee}
                                            disabled={mode === "view" || loading || disabled || iPermissions?.W09F5650 <= 2}
                                            iconExpr={{key: 'UserPictureURL', prefix: Config.getCDNPath()}}
                                            viewType={"underlined"}
                                            displayExpr={'EmployeeName'}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            subText={'EmployeeID'}
                                            label={Config.lang("Nhan_vien")}
                                            placeholder={Config.lang("Chon")}
                                            renderSelectedItem={e => {
                                                if (!e) return null;
                                                const {data} = e;
                                                return (
                                                    <div className={"display_row align-center"}>
                                                        <Avatar
                                                            clearAble={false}
                                                            width={24}
                                                            height={24}
                                                            readOnly
                                                            src={Config.getUserPicture(data.UserPictureURL)}
                                                            hoverAble={false}
                                                            style={{marginRight: 8}}
                                                        />
                                                        <Typography type={"p1"} lineClamp={1} hoverTooltip>{`${data?.EmployeeID ?? ""} - ${data?.EmployeeName ?? ""}`}</Typography>
                                                    </div>
                                                )
                                            }}
                                            onChange={e => this.handleChange("EmployeeID", e)}
                                            onInput={(e) => {
                                                this.filterCboEmployees.search = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <DatePicker
                                            error={error ? error["ValidDate"] : ""}
                                            label={Config.lang('Ngay_hieu_luc')}
                                            displayFormat={'DD/MM/YYYY'}
                                            placeholder={Config.lang("Chon_ngay")}
                                            required
                                            clearAble
                                            disabled={disabled || loading}
                                            actionIconAt={'end'}
                                            value={dataForm.ValidDate}
                                            onChange={e => this.handleChange("ValidDate", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Dropdown
                                            error={error ? error["NewDepartmentID"] : ""}
                                            required={true}
                                            displayExpr={"{DepartmentID} - {DepartmentName}"}
                                            valueExpr={"DepartmentID"}
                                            label={Config.lang("Phong_ban_moi")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => this.handleChange("NewDepartmentID", e)}
                                            dataSource={getCboDepartments}
                                            disabled={disabled || loading}
                                            value={dataForm.NewDepartmentID}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Dropdown
                                            error={error ? error["NewTeamID"] : ""}
                                            displayExpr={"{TeamID} - {TeamName}"}
                                            valueExpr={"TeamID"}
                                            label={Config.lang("To_nhom_moi")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => this.handleChange("NewTeamID", e)}
                                            dataSource={dataTeam}
                                            disabled={disabled || loading}
                                            value={dataForm.NewTeamID}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Dropdown
                                            allowSearch
                                            error={error ? error["ProjectID"] : ""}
                                            dataSource={dataCboProjects?.rows || []}
                                            total={dataCboProjects?.total ?? 0}
                                            skip={this.filterCboProject.skip}
                                            limit={this.filterCboProject.limit}
                                            value={dataForm.NewProject}
                                            valueObjectDefault={{
                                                ProjectID: dataForm.NewProject,
                                                ProjectName: dataForm.NewProjectName,
                                            }}
                                            disabled={disabled || loading}
                                            loading={projectsLoading}
                                            displayExpr={"{ProjectID} - {ProjectName}"}
                                            valueExpr={'ProjectID'}
                                            label={Config.lang("Du_an")}
                                            placeholder={Config.lang("Chon")}
                                            renderItem={e => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.ProjectID + ' - ' + data.ProjectName;
                                            }}
                                            onChange={e => this.handleChange("ProjectID", e)}
                                            onInput={(e) => {
                                                this.filterCboProject.strSearch = e.target.value;
                                                this.filterCboProject.skip = 0;
                                                this.loadCboProjects(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProject.skip = e.skip;
                                                this.filterCboProject.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Dropdown
                                            error={error && error["NewWorkID"]}
                                            valueExpr={"WorkID"}
                                            displayExpr={"{WorkID} - {WorkName}"}
                                            label={Config.lang("Cong_viec")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => this.handleChange("NewWorkID", e)}
                                            dataSource={getCboWorks}
                                            disabled={disabled || loading}
                                            value={dataForm.NewWorkID}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            allowSearch
                                            error={error ? error["NewDirectManagerID"] : ""}
                                            dataSource={dataCboDirectManagers}
                                            value={dataForm.NewDirectManagerID}
                                            disabled={disabled || loading}
                                            loading={cboDirectManagersLoading}
                                            iconExpr={{key: 'UserPictureURL', prefix: Config.getCDNPath()}}
                                            viewType={"underlined"}
                                            valueExpr={"EmployeeID"}
                                            keyExpr={"EmployeeID"}
                                            label={Config.lang("Nguoi_quan_ly_truc_tiep")}
                                            placeholder={Config.lang("Chon")}
                                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                                            renderSelectedItem={e => {
                                                if (!e) return null;
                                                const {data} = e;
                                                if (data?.UserPictureURL) {
                                                    if (data.UserPictureURL.indexOf('http') < 0) {
                                                        data.UserPictureURL = Config.getCDNPath() + data.UserPictureURL;
                                                    }
                                                }
                                                return (
                                                    <div className={"display_row align-center"}>
                                                        <Avatar
                                                            clearAble={false}
                                                            width={24}
                                                            height={24}
                                                            readOnly
                                                            src={data?.UserPictureURL ?? ''}
                                                            hoverAble={false}
                                                            style={{marginRight: 8}}
                                                        />
                                                        <Typography type={"p1"} lineClamp={1}>{data?.EmployeeName ?? ""}</Typography>
                                                    </div>
                                                )
                                            }}
                                            onChange={(e) => this.handleChange("NewDirectManagerID", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            error={error && error["ProContent"]}
                                            clearAble
                                            valueObjectDefault={ mode === "view" && !this.isChangeForm ? {
                                                ReasonID: "",
                                                ProContent: dataForm.ProContent,
                                            } : null}
                                            valueExpr={"ProContent"}
                                            renderSelectedItem={e => e?.data?.ProContent ?? ""}
                                            renderItem={e => {
                                                if(!e) return null;
                                                const {data} = e;
                                                return `${data?.ReasonID ?? ""} 
                                                        ${data?.ReasonID ? "-" : ""}
                                                        ${data?.ProContent ?? ""}`
                                            }}
                                            label={Config.lang("Ly_do")}
                                            onChange={e => this.handleChange("ProContent", e)}
                                            dataSource={getCboReasons}
                                            disabled={disabled || loading}
                                            value={dataForm.ProContent}
                                            onInput={e => this.handleChange("ProContent", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            error={error && error["ProNotice"]}
                                            value={dataForm.ProNotice}
                                            label={Config.lang('Ghi_chu')}
                                            disabled={disabled || loading}
                                            onChange={e => this.handleChange("ProNotice", e)}
                                        />
                                    </Col>
                                </Row>
                                <FormGroup>
                                    {iPermissions?.W09F5602 > 1 && <Checkbox
                                        className={"mgt4x"}
                                        label={Config.lang("De_xuat_dieu_chinh_luong")}
                                        checked={Boolean(dataForm.IsSalaryAdjustment)}
                                        onChange={this.onChangeChkSalary}
                                    />}
                                    <Accordion expanded={Boolean(dataForm.IsSalaryAdjustment)} boxShadow={false}>
                                        <AccordionSummary style={{ display: 'none' }} >
                                        </AccordionSummary>
                                        <AccordionDetails className={"pd0"}>
                                            <Row>
                                                {
                                                    iPermissions?.W09F5602 > 1 && Number(dataForm.IsSalaryAdjustment) === 1 && dataColumnSalary && dataColumnSalary.map((data, index) => {
                                                        if(data.Disabled) return null;
                                                        let key = data.Code.slice(-2);
                                                        const value = dataForm["NewBASE" + key] || dataForm["NewBASE" + key] === 0 ? Number(dataForm["NewBASE" + key]) : 0;
                                                        return(
                                                            <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                                <NumberFormat
                                                                    customInput={TextField}
                                                                    label={data.CaptionName + ' ' + Config.lang('Moi').toLowerCase()}
                                                                    thousandSeparator={true}
                                                                    decimalScale={data.Decimals ? data.Decimals : 0}
                                                                    value={String(value)}
                                                                    onValueChange={e => this.handleChangeDataSalary("NewBASE" + key,e)}
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    // variant={"standard"}
                                                                    // margin={"normal"}
                                                                />
                                                            </Col>
                                                        )
                                                    })
                                                }
                                                {
                                                    iPermissions?.W09F5602 > 1 && Number(dataForm.IsSalaryAdjustment) === 1 && getCaption && getCaption.map((data, index) => {
                                                        if(data.Disabled) return null;
                                                        let key = data.Code.slice(-2);
                                                        const value = dataForm["NewSalCoefficient" + key] || dataForm["NewSalCoefficient" + key] === 0 ? Number(dataForm["NewSalCoefficient" + key]) : 0;
                                                        return(
                                                            <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                                <NumberFormat
                                                                    customInput={TextField}
                                                                    label={data.CaptionName + ' ' + Config.lang('Moi').toLowerCase()}
                                                                    thousandSeparator={true}
                                                                    decimalScale={data.Decimals ? data.Decimals : 0}
                                                                    value={String(value)}
                                                                    onValueChange={e => this.handleChangeDataSalary("NewSalCoefficient" + key,e)}
                                                                    fullWidth
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    variant={"standard"}
                                                                    margin={"normal"}
                                                                />
                                                            </Col>
                                                        )
                                                    })
                                                }

                                            </Row>
                                        </AccordionDetails>
                                    </Accordion>
                                </FormGroup>
                                <FormGroup>
                                    <Checkbox label={Config.lang("De_xuat_dieu_chinh_chuc_danh")}
                                              checked={!!dataForm.IsDutyAdjustment}
                                              disabled={iPermissionW09F2080 < 3 || iPermissions?.W09F2150 < 3 || mode === "view"}
                                              onChange={this.onChangeDutyAdjustment}/>
                                    <Accordion expanded={!!dataForm.IsDutyAdjustment} boxShadow={false}>
                                        <AccordionSummary  style={{ display: 'none' }} expandIcon={null}/>
                                        <AccordionDetails className={"pd0"}>
                                            <Row>
                                                <Col xs={12}>
                                                    <Dropdown dataSource={getCboPosition}
                                                              displayExpr={"DutyName"}
                                                              valueExpr={"DutyID"}
                                                              label={Config.lang("Chuc_danh_moi")}
                                                              value={dataForm?.NewDutyID || ""}
                                                              onChange={e => this.handleChange("NewDutyID", e)}/>
                                                </Col>
                                            </Row>
                                        </AccordionDetails>
                                    </Accordion>
                                </FormGroup>
                                <FormGroup>
                                    <Accordion expand={false} boxShadow={false}>
                                        <AccordionSummary className={"pd0"} expandIconAt={"start"}>
                                            {Config.lang("Thong_tin_cu")}
                                        </AccordionSummary>
                                        <AccordionDetails className={"pd0"}>
                                            <Row>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <span style={{fontWeight: 600}}>{Config.lang("Phong_ban")}:</span>&nbsp;
                                                    {dataOldForm.DepartmentName || ""}
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <span style={{fontWeight: 600}}>{Config.lang("To_nhom")}:</span>&nbsp;
                                                    {dataOldForm.TeamName || ""}
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <span style={{fontWeight: 600}}>{Config.lang("Du_an")}:</span>&nbsp;
                                                    {dataOldForm.ProjectName || ""}
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <span style={{fontWeight: 600}}>{Config.lang("Cong_viec")}:</span>&nbsp;
                                                    {dataOldForm.WorkName || ""}
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <span style={{fontWeight: 600}}>{Config.lang("Nguoi_quan_ly_truc_tiep")}:</span>&nbsp;
                                                    {dataOldForm.DirectManagerName || ""}
                                                </Col>
                                                {
                                                    iPermissions?.W09F5602 > 0 && dataColumnSalary && dataColumnSalary.map((data, index) => {
                                                        if(data.Disabled) return null;
                                                        let key = data.Code.slice(-2);
                                                        return(
                                                             <Col key={index} xs={12} sm={6} md={6} lg={6}>
                                                                <span style={{fontWeight: 600}}>{data.CaptionName}:</span>&nbsp;
                                                                {dataOldForm["BASE" + key] || dataOldForm["BASE" + key] === 0 ?   new Intl.NumberFormat().format(Number(dataOldForm["BASE" + key])) : ""}
                                                            </Col>
                                                        )
                                                    })
                                                }
                                                {iPermissions?.W09F5602 > 0 && getCaption && getCaption.map((field, indx) => {
                                                    if (field.Disabled) return null;
                                                    const no = field && field.Code ? field.Code.slice(-2) : "";
                                                    const dataField = "SalCoefficient" + no;
                                                    return (
                                                        <Col key={indx} xs={12} sm={6} md={6} lg={6}>
                                                            <span style={{fontWeight: 600}}>{field.CaptionName || ""}:</span>&nbsp;
                                                            {Number(dataOldForm[dataField] || 0).toLocaleString()}
                                                        </Col>
                                                    );
                                                })}
                                                <Col xs={12} sm={12} md={6} lg={6}>
                                                    <span style={{fontWeight: 600}}>{Config.lang("Chuc_danh")}:</span>&nbsp;
                                                    {dataOldForm.DutyName || ""}
                                                </Col>
                                            </Row>
                                        </AccordionDetails>
                                    </Accordion>
                                </FormGroup>
                                <FormGroup style={{marginBottom: 34}}>
                                    <Attachment
                                        maxFile={5}
                                        maxSize={"5mb"}
                                        multiple
                                        unitSize={"mb"}
                                        data={dataOldAttachments}
                                        disabled={disabled || loading || uploading}
                                        onChange={this.onChangeAttachments}
                                        uploadErrorInfo={{
                                            existingFile: Config.lang('File_da_duoc_dinh_kem'),
                                            maxFile     : Config.lang('File_vuot_qua_so_luong_cho_phep'),
                                            maxSize     : Config.lang('File_vuot_qua_dung_luong_cho_phep'),
                                            fileType    : Config.lang("File_khong_dung_dinh_dang"),
                                        }}

                                    />
                                </FormGroup>
                            </React.Fragment>
                        </TabPanel>
                        <TabPanel index={1}>
                            <React.Fragment>

                            </React.Fragment>
                        </TabPanel>
                    </TabContainer>
                </Modal.Content>
                <Modal.Actions>
                        <Button
                            text={Config.lang("Luu")}
                            color={"info"}
                            disabled={loading || disabled || uploading}
                            startIcon={"save"}
                            viewType={"filled"}
                            size={"large"}
                            className={"pull-right"}
                            onClick={this.onSave}
                        />
                </Modal.Actions>
            </>
        );
    }
}

W09F2080Popup.propTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,

    onClose: PropTypes.func
};

export default compose(
    connect(
        state => ({
            getCboDepartments: state.general.getCboDepartments,
            getCboTeam: state.general.getCboTeam,
            getCboWorks: state.general.getCboWorks,
            getCboPosition: state.general.getCboDuty2,
            getRequiredFields: state.W09F2080.getRequiredFields,
            getCboReasons: state.W09F2080.getCboReasons,
            getAttachmentsByTransID: state.general.getAttachmentsByTransID,
            getCaption: state.W09F2150.getCaption,
        }),
        dispatch => ({
            w09f2080Actions: bindActionCreators(W09F2080Actions, dispatch),
            w09F2150Actions: bindActionCreators(W09F2150Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch)
        })
    )
)(W09F2080Popup);
