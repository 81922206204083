/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/25/2020
 * @Example
 */

import React                             from "react";
import {bindActionCreators, compose}     from "redux";
import {withStyles} from "@material-ui/core";
import {connect}                         from "react-redux";
import ActionToolbar                     from "../../../common/toolbar/action-toolbar";
import ButtonGeneral                     from "../../../common/button/button-general";
import Modal                             from "../../../common/modal/modal";
import {Combo}                           from "../../../common/form-material";
import DateBoxPicker                     from "../../../common/form-material/date-box";
import UserImage                         from "../../../common/user/user-image";
import UserName                          from "../../../common/user/user-name";
import Status                            from "../../../common/status/status";
import GridContainer                     from "../../../grid-container/grid-container";
import Filter                            from "../../../filter/filter";
import Config                            from "../../../../config";
import * as generalActions               from "../../../../redux/general/general_actions";
import * as W09F2150Actions              from "../../../../redux/W0X/W09F2150/W09F2150_actions";
import {Column}                          from "devextreme-react/data-grid";
import {Image, FormGroup, Row, Col}      from "react-bootstrap";
import moment                            from "moment";
import {browserHistory}                  from "react-router";
import _                                 from "lodash";
import W09F2150Popup                     from "./W09F2150Popup";
import {ButtonIcon}                      from 'diginet-core-ui/components';
import W84F3005                          from '../../../W8X/W84/W84F3005/W84F3005';

const styles = {
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000"
    },
};

class W09F2150 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission:       0,
            mode:              "add",
            rowData:           null,
            ProDateTo:         "",
            ProDateFrom:       "",
            showW09F2150Popup: false,
            dataCboEmployees:  {
                rows:  [],
                total: 0
            },
            dataCboProjects:   {
                rows:  [],
                total: 0
            },
            loading:           {
                cboEmployees: false,
                cboProjects:  false,
            },
            showW84F3005Popup: null
        };
        this.loading = {
            cboEmployees: false,
            cboProjects:  false,
        };
        this.filter = {
            Employee:    "%",
            Project:     "%",
            AppStatusID: "%",
            DutyID:      "",
            skip:        0,
            limit:       10
        };
        this.filterCboEmployees = {
            timer:     null,
            strSearch: "",
            skip:      0,
            limit:     50
        };
        this.filterCboProject = {
            timer:     null,
            strSearch: "",
            skip:      0,
            limit:     50
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2150", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    onSearch = () => {
        this.loadDataGrid();
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: {EmployeeID: data.EmployeeID}
        });
    };

    loadRewardCaption = () => {
        this.props.W09F2150Actions.loadRewardCaption({Language: Config.language || "84"}, (error) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    onAdd = (typeAdd = 0) => {
        this.setState({
            mode: "add",
            showW09F2150Popup: true,
            typeAdd,
        });
    };

    onView = (e) => {
        const {data} = e.row;
        this.setState({
            mode: "view",
            rowData: data,
            showW09F2150Popup: true
        });
    };

    onDelete = data => {
        const {ProTransID} = data;
        const params = {
            DivisionID: Config.getDivisionID(),
            ProTransID,
            Mode: 2
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            this.props.W09F2150Actions.deleteGrid(params, error => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else {
                    Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
                    this.loadDataGrid();
                }
            });
        });
    };

    loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W09F2150",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.loading.cboEmployees = true;
        this.setState({loading: this.loading});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.loading.cboEmployees = false;
            this.setState({loading: this.loading});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboProjects = (isReset) => {
        const param = {
            FormID: "W09F2150",
            skip: this.filterCboProject.skip,
            limit: this.filterCboProject.limit,
            search: this.filterCboProject.strSearch
        };
        this.loading.cboProjects = true;
        this.setState({loading: this.loading});
        this.props.generalActions.getCboProjects(param, (error, data) => {
            this.loading.cboProjects = false;
            this.setState({loading: this.loading});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboDuty = () => {
        this.props.generalActions.getCboDuty2(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W09F2150",
            Language: Config.language || "84"
        };

        this.props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadDataGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const {ProDateTo, ProDateFrom} = this.state;
        const {Employee, Project, AppStatusID, DutyID, limit, skip} = this.filter;
        const param = {
            TransID: VoucherID || "",
            Language:    Config.language || 84,
            ProDateFrom: ProDateFrom || null,
            ProDateTo:   ProDateTo || null,
            EmployeeID:  Employee && Employee.EmployeeID ? Employee.EmployeeID : "%",
            ProjectID:   Project && Project.ProjectID ? Project.ProjectID : "%",
            AppStatusID: AppStatusID || AppStatusID === 0 ? String(AppStatusID) : "%",
            DutyID: DutyID || "%",
            skip:        skip,
            limit:       limit
        };
        this.setState({gridLoading: true});
        this.props.W09F2150Actions.loadDataGrid(param, error => {
            this.setState({gridLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.loadCboEmployees();
        this.loadCboProjects();
        this.loadCboAppStatus();
        this.loadCboDuty();
        this.loadDataGrid();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {getDataGrid} = this.props;
        Config.callChildForm({
            FormID: "W09F2150",
            // ID: voucher_id,
            keyExpr: "ProTransID",
            data: getDataGrid && getDataGrid.rows ? getDataGrid.rows : [],
            onLoad: (params) => this.loadDataGrid(params && params.ID),
            onAction: (e, data) => this.onView({ row: { data: data } })
        }, this.props);
    }

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    onCloseModal = (mode) => {
        if(mode !== "close") {
            this.loadDataGrid();
        }
        this.setState({
            showW09F2150Popup: false
        });
    };

    filterChange = (key, data) => {
        const value = _.get(data, "value", _.get(data, "target.value", ""));
        const keyState = ["ProDateFrom", "ProDateTo"];
        if (keyState.includes(key)) {
            this.setState({
                [key]: value
            });
            return;
        }
        this.filter[key] = value;
    };

    renderFilter = () => {
        const {
            getCboDuty,
            getCboAppStatus,
            classes
        } = this.props;
        const {Project, AppStatusID, Employee, DutyID} = this.filter;
        const {ProDateTo, ProDateFrom, dataCboEmployees, dataCboProjects, loading} = this.state;

        return (
            <Filter
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div className={classes.divText}>
                                                {Config.lang("Tu")}
                                            </div>
                                            <DateBoxPicker
                                                dateBoxProps={{max: ProDateTo ? ProDateTo : moment()}}
                                                style={{marginLeft: "5px"}}
                                                onValueChanged={e => this.filterChange("ProDateFrom", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={ProDateFrom || ""}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div className={classes.divText}>
                                                {Config.lang("Den").toLowerCase()}
                                            </div>
                                            <DateBoxPicker
                                                dateBoxProps={{min: ProDateFrom, max: moment()}}
                                                style={{marginLeft: "5px"}}
                                                onValueChanged={e => this.filterChange("ProDateTo", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={ProDateTo || ""}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => this.filterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={AppStatusID === "%" ? "" : AppStatusID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={Employee === "%" ? null : Employee}
                                            stylingMode={'outlined'}
                                            loading={loading.cboEmployees}
                                            label={Config.lang('Nhan_vien')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.filterChange("Employee", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboProjects}
                                            skip={this.filterCboProject.skip}
                                            limit={this.filterCboProject.limit}
                                            displayExpr={'ProjectName'}
                                            keyExpr={'ProjectID'}
                                            valueExpr={'ProjectID'}
                                            value={Project === "%" ? null : Project}
                                            loading={loading.cboProjects}
                                            stylingMode={'outlined'}
                                            label={Config.lang('Du_an')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.ProjectID + ' - ' + data.ProjectName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.filterChange("Project", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboProject.strSearch = e.target.value;
                                                this.filterCboProject.skip = 0;
                                                this.loadCboProjects(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProject.skip = e.skip;
                                                this.filterCboProject.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={Config.storeDataSoureDevExtreme(getCboDuty)}
                                            displayExpr={item =>
                                                item && `${item.DutyID} - ${item.DutyName}`
                                            }
                                            valueExpr={"DutyID"}
                                            stylingMode={"outlined"}
                                            value={DutyID}
                                            showClearButton={true}
                                            label={Config.lang("Chuc_vu")}
                                            onValueChanged={e => this.filterChange("DutyID", e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "7px 0",
                                }}
                            >
                                <ButtonGeneral
                                    name={Config.lang("Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    renderItem = (e) => {
        const {classes} = this.props;
        const { data } = e.row;
        const date = data.ProposalDate ? moment.utc(data.ProposalDate).format("DD/MM/YYYY HH:mm:ss") : "";
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} />
                <div>
                    <div style={{ fontSize: '1.12rem' }}><UserName data={data}/></div>
                    <div className={classes.divText}>{data.DepartmentName || ""}</div>
                    <div className={classes.dateInfo}>
                        <Image src={require("../../../../assets/images/icon-calendar.svg")} />
                        <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                            {data.ProposalDate && date}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const { data } = e.row;
        const { classes } = this.props;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {data.ProNotice && (
                    <div>{Config.lang("Ghi_chu") + ": " + (data.ProNotice || "")}</div>
                )}
                {data.ProjectName && (
                    <div>{Config.lang("Du_an_cong_trinh") + ": " + (data.ProjectName || "")}</div>
                )}
                {data.ProposerName && (
                    <div>{Config.lang("Nguoi_lap") + ": " + (data.ProposerName || "")}</div>
                )}
                {data.DutyName && (
                    <div>{Config.lang("Chuc_vu") + ": " + (data.DutyName || "")}</div>
                )}
                {data.ApproverName && (
                    <div>{Config.lang("Nguoi_duyet")}: {data.ApproverName || ""} - {" "}
                        {data.AppDepartmentID || ""} - {data.ApprovalDate || ""}
                    </div>
                )}
                {data.AppStatusID > 0 && <div>
                    {Config.lang("Ghi_chu_duyet") + ": " + (data.ApprovalNotes || "")}
                </div>}
            </div>
        );
    };

    renderStatus = e => {
        const {data} = e.row;
        return <Status data={data}/>;
    };

    renderAction = e => {
        const {data} = e.row;
        return (
            <div className={"display_row align-center"}>
                <ButtonIcon
                    name="history"
                    circular
                    viewType={'text'}
                    className={'mgr5'}
                    onClick={() => this.onHistory(data?.ProTransID)}
                />
                <ButtonIcon
                    name="view"
                    circular
                    viewType={'text'}
                    className={'mgr5'}
                    onClick={() => this.onView(e)}
                />
                <ButtonIcon
                    disabled={data && Number(data.AppStatusID) !== 0}
                    name="delete"
                    circular
                    viewType={'text'}
                    onClick={() => this.onDelete(data)}
                />
            </div>
        );
    };

    onHistory = (value = null) => {
        this.setState({ showW84F3005Popup: value });
    };

    render() {
        const {getDataGrid} = this.props;
        const {iPermission, gridLoading, showW09F2150Popup, mode, rowData, showW84F3005Popup} = this.state;
        if (iPermission <= 0) return null;

        return (
            <>
                <Modal open={showW09F2150Popup}
                       maxWidth={"md"}
                       fullWidth={true}
                       onClose={() => this.onCloseModal('close')}
                       title={Config.lang("Lap_de_xuat_thay_doi_chuc_danh")}
                >
                    <W09F2150Popup
                        type={mode === "add"}
                        mode={mode}
                        data={rowData}
                        onClose={() => this.onCloseModal()}
                    />
                </Modal>

                {/*Modal xem lịch sử*/}
                {showW84F3005Popup &&
                    <W84F3005 open={!!showW84F3005Popup}
                              onClose={() => this.onHistory()}
                              FormID="W09F2150"
                              TransID={showW84F3005Popup}
                    />
                }

                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("De_xuat_thay_doi_chuc_danh")}>
                    <ButtonGeneral name={Config.lang('Them')}
                                   disabled={gridLoading || !iPermission >= 2}
                                   typeButton={'add'}
                                   style={{ textTransform: 'uppercase' }}
                                   size={"large"}
                                   onClick={this.onAdd} />
                </ActionToolbar>
                <FormGroup>
                    <GridContainer
                        style={{ border: 'none' }}
                        dataSource={getDataGrid && getDataGrid.rows ? getDataGrid.rows : []}
                        keyExpr={"ProTransID"}
                        totalItems={getDataGrid && getDataGrid.total ? getDataGrid.total : 0}
                        itemPerPage={this.filter.limit}
                        skipPerPage={this.filter.skip}
                        height={Config.getHeightGrid()}
                        typePaging={"remote"}
                        loading={gridLoading}
                        rowAlternationEnabled={false}
                        showRowLines={true}
                        showBorders={false}
                        showColumnLines={false}
                        showColumnHeaders={false}
                        noDataText={Config.lang("No_data")}
                        onChangePage={this.onChangePage}
                        onChangePerPage={this.onChangePerPage}
                    >
                        <Column
                            dataField={'EmployeeName'}
                            cellRender={this.renderItem}
                            // width={300}
                        />
                        <Column cellRender={this.renderInfo}/>
                        <Column
                            dataField={'AppStatusID'}
                            visible={false}
                        />
                        <Column
                            alignment={"center"}
                            dataField={'AppStatusName'}
                            cellRender={this.renderStatus}
                            minWidth={150}
                            maxWidth={200}
                        />
                        <Column
                            dataField={'AppStatusName'}
                            cellRender={this.renderAction}
                            width={150}
                        />
                    </GridContainer>
                </FormGroup>
            </>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboDuty: state.general.getCboDuty2,
            getCboAppStatus: state.general.getCboAppStatus,
            getDataGrid: state.W09F2150.getDataGrid,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W09F2150Actions: bindActionCreators(W09F2150Actions, dispatch)
        })
    ),
    withStyles(styles, {withTheme: true})
)(W09F2150);
