/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 3/19/2020
 * @Example
 */

import React from "react";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Approvals from "../../../approvals/approvals";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2081Actions from "../../../../redux/W0X/W09F2081/W09F2081_actions";
import * as W09F2150Actions  from "../../../../redux/W0X/W09F2150/W09F2150_actions";
import {
    MoneyInput,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    Col,
    Row,
    TextInput, Avatar
} from "diginet-core-ui/components";

class W09F2081 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataForm: {},
            VoucherID: "",
            dataColumnSalary: [],
            loading: false
        }
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2081", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadCaption = () => {
        this.props.w09F2150Actions.loadCaption({Language: Config.language || "84" },(error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadDynamicColumnSalary = () => {
        const params = {
               Language: Config.language || 84
        };
         this.props.w09F2081Actions.getDynamicColumnSalary(params,(error,data) => {
              if (error) {
                   Config.popup.show("ERROR", error);
                     return false;
                  }
               if(data) {
                     this.setState({dataColumnSalary : data});
                   }
            });
        };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return false;
        this.loadCaption();
        this.loadDynamicColumnSalary();
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W09F2081",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.setState({
            dataForm: [],
            loading: true
        });
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            this.setState({loading: false})
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb (data);
            }
        });
    };

    renderFormInfo = (data) => {
        const {getCaption} = this.props;
        const {dataColumnSalary} = this.state;
        return (
            <>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Nhan_vien")}
                                value={Config.helpers.getObjectValue(data, "EmployeeName", "")}
                                startIcon={
                                    <Avatar
                                        readOnly
                                        width={24}
                                        height={24}
                                        src={Config.getUserPicture(data?.UserPictureURL)}
                                    />
                                }
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                                    <Checkbox
                                        style={{paddingTop: 24}}
                                        label={Config.lang("De_xuat_dieu_chinh_luong")}
                                        checked={!!data.IsSalaryAdjustment}
                                        color="primary"
                                    />
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Ma_nhan_vien")}
                                value={Config.helpers.getObjectValue(data, "EmployeeID", "")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Phong_ban_moi")}
                                value={Config.helpers.getObjectValue(data, "NewDepartmentName", "")}
                            />
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("To_nhom_moi")}
                                value={Config.helpers.getObjectValue(data, "NewTeamName", "")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Du_an_moi")}
                                value={Config.helpers.getObjectValue(data, "NewProjectName", "")}
                            />
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Cong_viec_moi")}
                                value={Config.helpers.getObjectValue(data, "NewWorkName", "")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                readOnly
                                label={Config.lang("Ngay_hieu_luc")}
                                value={Config.convertDate(data.ValidDate, "")}
                            />
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                readOnly
                                label={Config.lang("Nguoi_quan_ly_truc_tiep_moi")}
                                value={Config.helpers.getObjectValue(data, "NewDirectManagerName", "")}
                            />
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                readOnly
                                label={Config.lang("Ly_do")}
                                value={Config.helpers.getObjectValue(data, "ProContent", "")}
                            />
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                readOnly
                                label={Config.lang("Ghi_chu")}
                                value={Config.helpers.getObjectValue(data, "ProNotice","")}
                            />
                        </Col>

                        {
                            data.IsSalaryAdjustment === 1 && dataColumnSalary.map((value,index) => {
                                 if(value.Disabled) return null;
                                 let key = value.Code.slice(-2);
                                 const item = data["NewBASE" + key] || data["NewBASE" + key] === 0 ? Number(data["NewBASE" + key]) : 0;
                                 return (
                                    <Col key={index} xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly
                                            label={Config.lang(value.CaptionName) + " " + Config.lang("Moi").toLowerCase()}
                                            value={item ? Number(item).toLocaleString() : ""}
                                        />
                                    </Col>
                                 )
                            })
                        }
                        {data.IsSalaryAdjustment === 1 && getCaption && getCaption.map((item, index) => {
                            if (item.Disabled) return null;
                            let no          = item && item.Code ? item.Code.slice(-2) : "";
                            const dataField = "NewSalCoefficient" + no;
                            return (<Col key={index} xs={12} sm={12} md={6} lg={6}>
                                <MoneyInput
                                    style={{paddingTop: 10}}
                                    allowZero={true}
                                    decimalDigit={item.Decimals ? item.Decimals : 0}
                                    disabledNegative
                                    label={item.CaptionName + ' ' + Config.lang('Moi').toLowerCase()}
                                    placeholder={Config.lang("Nhap")}
                                    readOnly
                                    value={data[dataField] ? String(data[dataField]) : "0"}
                                />
                            </Col>);
                        })}

                    {!!data?.IsDutyAdjustment ?
                        <Col xs={12}>
                            <Checkbox
                                readOnly
                                label={Config.lang("De_xuat_chuc_danh")}
                                checked={true}
                            />
                        </Col>
                    : ""}

                {!!data?.IsDutyAdjustment ?
                        <Col xs={12}>
                            <TextInput
                                readOnly
                                label={Config.lang("Chuc_danh_moi")}
                                value={data?.NewDutyName ?? ""}
                            />
                        </Col>
                : ""}
                    </Row>
                <div className={"mgt10"}>
                    <Accordion>
                        <AccordionSummary>
                           {Config.lang("Thong_tin_cu")}
                        </AccordionSummary>
                        <AccordionDetails>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <span style={{fontWeight: 600}}>{Config.lang("Phong_ban")}:</span>&nbsp;
                                        {Config.helpers.getObjectValue(data, "DepartmentName", "")}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <span style={{fontWeight: 600}}>{Config.lang("To_nhom")}:</span>&nbsp;
                                        {Config.helpers.getObjectValue(data, "TeamName", "")}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <span style={{fontWeight: 600}}>{Config.lang("Du_an_cu")}:</span>&nbsp;
                                        {Config.helpers.getObjectValue(data, "ProjectName", "")}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <span style={{fontWeight: 600}}>{Config.lang("Cong_viec_cu")}:</span>&nbsp;
                                        {Config.helpers.getObjectValue(data, "WorkName", "")}
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <span style={{fontWeight: 600}}>{Config.lang("Nguoi_quan_ly_truc_tiep_")}:</span>&nbsp;
                                        {Config.helpers.getObjectValue(data, "DirectManagerName", "")}
                                    </Col>
                                    {
                                        data.IsSalaryAdjustment === 1 && dataColumnSalary.map((value,index) => {
                                            if(value.Disabled) return null;
                                            let key = value.Code.slice(-2);
                                            const item = data["BASE" + key] || data["BASE" + key] === 0 ? Number(data["BASE" + key]) : 0;
                                            return (
                                                <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                    <span style={{fontWeight: 600}}>{Config.lang(value.CaptionName)}:</span>&nbsp;
                                                    {new Intl.NumberFormat().format(item)}
                                                </Col>
                                            )
                                        })
                                    }
                                    {
                                        Number(data.IsSalaryAdjustment) === 1 && getCaption && getCaption.map((field, indx) => {
                                        if (field.Disabled) return null;
                                        const no = field && field.Code ? field.Code.slice(-2) : "";
                                        const dataField = "SalCoefficient" + no;
                                        return (
                                            <Col key={indx} xs={12} sm={6} md={6} lg={6}>
                                                <span style={{fontWeight: 600}}>{field.CaptionName || ""}:</span>&nbsp;
                                                {Number(data[dataField] || 0).toLocaleString()}
                                            </Col>
                                        );
                                    })}
                                    {!!data?.IsDutyAdjustment ? <Col xs={12} sm={12} md={6} lg={6}>
                                        <span style={{fontWeight: 600}}>{Config.lang("Chuc_danh")}:</span>&nbsp;
                                        {Config.helpers.getObjectValue(data, "DutyName", "")}
                                    </Col> : ""}
                                </Row>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </>
        )
    };

    render() {
        const {iPermission, dataForm, VoucherID, loading} = this.state;
        if (!iPermission) return null;

        return (
            <React.Fragment>
                    <ActionToolbar
                        title={Config.lang("Duyet_de_xuat_dieu_chuyen_lao_dong")}
                    />
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                allowHistoryApproval
                                parentProps={this.props}
                                loading={loading}
                                FormID={"W09F2081"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                selectedRowKey={VoucherID}
                                disabledApproval={iPermission < 2}
                                singleClick
                                formInfoRender={this.renderFormInfo}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }

}

export default compose(connect(state=>({
        getCaption: state.W09F2150.getCaption,
    }),
    (dispatch) => ({
        w09F2081Actions: bindActionCreators(W09F2081Actions, dispatch),
        w09F2150Actions: bindActionCreators(W09F2150Actions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
    })
))(W09F2081);
