/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/7/2020
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup, Image} from "react-bootstrap"
import withStyles from "@material-ui/core/styles/withStyles";
import Approvals from "../../../approvals/approvals";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as w09f2060 from "../../../../redux/W0X/W09F2060/W09F2060_actions";
import LabelText from "../../../common/label-text/label-text";
import InlineSVG from "react-inlinesvg";
import {Input, FormLabel as Label} from "@material-ui/core";
import Filter from "../../../filter/filter";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import * as generalActions from "../../../../redux/general/general_actions";
import DateBoxPicker from "../../../common/form-material/date-box";
import { Combo } from "../../../common/form-material";
import ButtonGeneral from "../../../common/button/button-general";
import _ from "lodash";

const styles = theme => ({
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
    },
    divAvatarText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: "1rem",
        color: "#FFF"
    }
});
class W09F2061 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            loading: false,
            loadingCboProject: false,
            loadingCboEmployee: false,
            dataForm: {},
            dataCboEmployees: { rows:[], total: 0 },
            dataCboProjects: { rows:[], total: 0 },
            DateFrom: null,
            DateTo: null,
        };
        this.filter = {
            Project: "",
            Employee: "",
            DepartmentID: "",
            ApprovalStatus:"",
            searchvalue: "",
            skip: 0,
            limit: 10
          };
        this.filterCboProject = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboEmployee = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboEmployees = {
            strSearch: "",
            skip: 0,
            limit: 50
        }
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2061", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };


    loadGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : (voucher_id ? voucher_id : "");
        const { DateFrom, DateTo } = this.state;
        const { Employee, Project } = this.filter;
        const params = {
            VoucherID,
            FormID: "W09F2061",
            Language: Config.language || '84',
            DivisionID: Config.getDivisionID(),
            ..._.omit(this.filter, ["Employee", "Project"]),
            EmployeeID: _.get(Employee, "EmployeeID", ""),
            ProjectID: _.get(Project, "ProjectID", ""),
            DateFrom, 
            DateTo,
        };
        this.setState({ loading: true });
        this.props.approvalActions.getGridApprovals(params, (err) => {
            this.setState({ loading: false });
            if (err) return Config.popup.show("INFO", _.get(err, "message", "") || Config.lang("DHR_Loi_chua_xac_dinh"));
        });
    };

    handleFilter = (key,e) => {
        const value = _.get(e,"value", _.get(e,"target.value", ""));
        const arrDataField = ["DateFrom", "DateTo"];
        if(arrDataField.includes(key)){
            this.setState({
                [key]: value
            });
            return;
        }
        this.filter[key] = value;
    }

    loadCboEmployees = (isReset) => {
        const params = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W09F2061",
            Language: Config.language || '84',
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.state.searchValue
        };
        this.setState({loadingCboEmployee: true});
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setState({loadingCboEmployee: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };
    
      loadCboProjects = (isReset) => {
        const params = {
          HostID: "",
          FormID: "W09F2061",
          Language: Config.language || "84",
          skip: this.filterCboProject.skip,
          limit: this.filterCboProject.limit,
          search: this.filterCboProject.strSearch
        };
        this.setState({loadingCboProject: true})
        this.props.generalActions.getCboProjects(params, (error, data) => {
            this.setState({loadingCboProject: false})
          if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
          if (data) {
            const {dataCboProjects} = this.state;
            const rows = data && data.rows ? data.rows : data;
            const total = data && data.total ? data.total : data.length;
            this.setState({
              dataCboProjects: {
                rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                total: total
              }
            })
          }
        });
      };
    
      loadCboDepartment = () => {
        const params = {
          HostID: "",
          FormID: "W09F2061",
          DivisionID: Config.getDivisionID(),
          Language: Config.language || "84"
        };
        this.props.generalActions.getCboDepartments(params, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
      };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W09F2061",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.setState({loading: true});
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb (data);
            }
        });
    };

    loadDynamicFields = () => {
        this.props.w09f2060Actions.loadRewardCaption((error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadGrid();
        this.loadCboEmployees();
        this.loadCboProjects();
        this.loadDynamicFields();
        this.loadCboDepartment();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {getGridApproval} = this.props;
        Config.callChildForm({
            FormID: "W09F2061",
            // ID: voucher_id,
            keyExpr: "VoucherID",
            data: getGridApproval && getGridApproval.rows ? getGridApproval.rows : [],
            onLoad: (params) => this.loadGrid(params && params.ID),
            onAction: (e, data) => {
                const {ID} = e || "";
                this.refs['Approvals'].showPopup(data);
                this.loadFormInfo(ID);
            }
        }, this.props);
    }

    //Form info..
    renderFormInfo = (data) => {
        const {classes, getRewardCaption} = this.props;
        const validDate = Config.helpers.getObjectValue(data, 'ValidDate', '');
        
        const type = _.get(data, "Type", "Employee")
        // switch (_.get(data, "Type", "")) {
        //     case "Employee":
        //         templateField = 
                   
        //         break;
        //     case "Department":
        //         templateField = 
        //         break;
        //     case "Project":
                
        //         break;
        //     default:
        //         break;
        // }
        const objFields = {
            Employee: {
                labelNameTitle: Config.lang("DHR_Nhan_vien"),
                labelIDTitle: Config.lang("DHR_Ma_nhan_vien"),
                labelName: _.get(data, "EmployeeName", ""),
                labelID: _.get(data, "EmployeeID", ""),
                avatar: _.get(data, "UserPictureURL", ""),
            },
            Department: {
                labelNameTitle: Config.lang("DHR_Phong_ban"),
                labelIDTitle: Config.lang("DHR_Ma_phong_ban"),
                labelName: _.get(data, "DepartmentName", ""),
                labelID: _.get(data, "DepartmentID", ""),
                avatar: _.get(data, "DepartmentName", "") ? _.get(data, "DepartmentName", "").charAt(0).toUpperCase() : "",
            },
            Project: {
                labelNameTitle: Config.lang("DHR_Du_an"),
                labelIDTitle: Config.lang("DHR_Ma_du_an"),
                labelName: _.get(data, "ProjectName", ""),
                labelID: _.get(data, "ProjectID", ""),
                avatar: _.get(data, "ProjectName", "") ? _.get(data, "ProjectName", "").charAt(0).toUpperCase() : "",
            },
        };
        return (
            <>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={8} lg={8} className={"mgb15"}>
                                <LabelText
                                    label={objFields[type].labelNameTitle}
                                    value={() => {
                                    return (
                                        <div className={"display_row align-center pdb5"} style={{ width: "100%", height: 37 }}>
                                            <div className={`${classes.divAvatar} ${type !== "Employee" ? classes.divAvatarText : ""}`}>
                                                {type === "Employee" ? (
                                                    objFields[type].avatar ? (
                                                        <Image
                                                            className={classes.imgAvatar}
                                                            src={Config.getUserPicture(objFields[type].avatar)}
                                                        />
                                                    ) : (
                                                        <InlineSVG
                                                            className={classes.imgAvatar}
                                                            src={require("../../../../assets/images/general/user_default.svg")}
                                                        />
                                                    )
                                                ) : (
                                                    objFields[type].avatar
                                                )}
                                            </div>
                                            <Input
                                                color={"primary"}
                                                readOnly={true}
                                                value={objFields[type].labelName}
                                                disableUnderline={true}
                                            />
                                        </div>
                                    );
                                }}
                                fullWidth={true}
                                />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className={"mgb15"}>
                            <LabelText
                                label={objFields[type].labelIDTitle}
                                value={objFields[type].labelID}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                label={Config.lang("DHR_Ngay_hieu_luc")}
                                value={Config.convertDate(validDate, "", "DD/MM/YYYY")}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                label={Config.lang("DHR_Cap_khen_thuong")}
                                value={Config.helpers.getObjectValue(data, 'ProDisRewardLevelID', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                label={Config.lang("DHR_Hinh_thuc_khen_thuong")}
                                value={Config.helpers.getObjectValue(data, 'ProDisRewardFormID', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                label={Config.lang("DHR_Noi_dung")}
                                value={Config.helpers.getObjectValue(data, 'ProContent', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                label={Config.lang("DHR_Ghi_chu")}
                                value={Config.helpers.getObjectValue(data, 'ProNotice', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        {getRewardCaption && getRewardCaption.length > 0 && getRewardCaption.map((field) => {
                            if (!field.Disabled) {
                                const vl = Config.helpers.getObjectValue(data, field.Code, 0);
                                return (
                                    <Col key={field.Code} xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                                        <LabelText
                                            label={field.Short}
                                            value={vl ? Number(vl).toLocaleString() : ""}
                                            fullWidth={true}
                                        />
                                    </Col>
                                );
                            }
                            return null;
                        })}
                    </Row>
                </FormGroup>
            </>
        );
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    renderFilter = () => {
        const { getCboStatus, getCboDepartments } = this.props;
        const { dataCboEmployees, dataCboProjects, DateTo, DateFrom, loadingCboProject, loadingCboEmployee } = this.state;
        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
                showHeader={false}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                                            <DateBoxPicker
                                                max={DateTo}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                width={"100%"}
                                                value={DateFrom}
                                                onValueChanged={(e) => this.handleFilter("DateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                                            <DateBoxPicker
                                                min={DateFrom}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                width={"100%"}
                                                value={DateTo}
                                                onValueChanged={(e) => this.handleFilter("DateTo", e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                    <Combo
                                            dataSource={getCboStatus}
                                            showClearButton={true}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"ApprovalStatus"}
                                            value={this.filter.ApprovalStatus}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            onValueChanged={e => this.handleFilter("ApprovalStatus", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            value={this.filter.Employee}
                                            loading={loadingCboEmployee}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Nhan_vien")}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.EmployeeID + " - " + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => this.handleFilter("Employee", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Combo.LoadMore
                                                dataSource={dataCboProjects}
                                                skip={this.filterCboProject.skip}
                                                limit={this.filterCboProject.limit}
                                                displayExpr={'ProjectName'}
                                                keyExpr={'ProjectID'}
                                                valueExpr={'ProjectID'}
                                                value={this.filter.Project}
                                                loading={loadingCboProject}
                                                stylingMode={'outlined'}
                                                label={Config.lang('DHR_Du_an')}
                                                itemRender={(e) => {
                                                    const {data} = e;
                                                    if (!data) return null;
                                                    return data.ProjectID + ' - ' + data.ProjectName;
                                                }}
                                                showClearButton={true}
                                                // showCheckBoxMode={"none"}
                                                onValueChanged={e => this.handleFilter("ProjectID", e)}
                                                onInputChanged={(e) => {
                                                    this.filterCboProject.strSearch = e.target.value;
                                                    this.filterCboProject.skip = 0;
                                                    this.loadCboProjects(true);
                                                }}
                                                onLoadMore={(e) => {
                                                    this.filterCboProject.skip = e.skip;
                                                    this.filterCboProject.limit = e.limit;
                                                    this.loadCboProjects();
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                        </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            dataSource={getCboDepartments}
                                            showClearButton={true}
                                            displayExpr={"DepartmentName"}
                                            valueExpr={"DepartmentID"}
                                            value={this.filter.DepartmentID}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Phong_ban")}
                                            onValueChanged={(e) => this.handleFilter("DepartmentID", e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>                           
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    color={"primary"}
                                    className={"pdl10 pdr10"}
                                    variant={"outlined"}
                                    style={{ textTransform: "uppercase" }}
                                    size={"large"}
                                    onClick={this.loadGrid}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    render() {
        const { getGridApproval } = this.props;
        const {iPermission, loading, dataForm, VoucherID} = this.state;
        if (!iPermission) return null;
        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Duyet_de_xuat_khen_thuong")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Approvals
                                allowHistoryApproval
                                FormID={"W09F2061"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                selectedRowKey={VoucherID}
                                dataSource={_.get(getGridApproval, "rows", [])}
                                loading={loading}
                                singleClick
                                filterRender={() => this.renderFilter()}
                                formInfoRender={this.renderFormInfo} //render form info with every screen
                                onSelectionChanged={this.onSelectionChanged} //action when select row...
                                onDrawerClosed={this.onDrawerClosed}
                                
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect((state) => ({
    getRewardCaption: state.W09F2060.getRewardCaption,
    getCboDepartments: state.general.getCboDepartments,
    getCboStatus: state.approvals.getCboStatus,
    getGridApproval: state.approvals.getGridApproval,
    getCboEmployees: state.general.getCboEmployees,
    getCboProjects: state.general.getCboProjects
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    approvalActions: bindActionCreators(ApprovalActions, dispatch),
    w09f2060Actions: bindActionCreators(w09f2060, dispatch),
})), withStyles(styles,{withTheme: true}))(W09F2061);
