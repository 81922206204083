/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/24/2021
 * @Example
 */

import React, {useState, useEffect, useRef} from "react";
import {FormGroup, Row, Col}                       from "react-bootstrap";
import ActionToolbar                               from "../../../common/toolbar/action-toolbar";
import {connect}                                   from "react-redux";
import {bindActionCreators}                        from "redux";
import Config                                      from "../../../../config";
import * as generalActions                         from "../../../../redux/general/general_actions";
import Filter                                      from "../../../filter/filter";
import * as W09F2090Actions from "../../../../redux/W0X/W09F2090/W09F2090_actions";
import GridContainer        from "../../../grid-container/grid-container";
import {Column}             from "devextreme-react/data-grid";
import GridActionBar        from "../../../grid-container/grid-actionbar";
import {
    Avatar,
    Button,
    ButtonIcon, Checkbox,
    DateRangePicker,
    Dropdown,
    Typography,
    Tooltip
} from 'diginet-core-ui/components';
import Status from "../../../common/status/status";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
import W09F2091 from "../W09F2091/W09F2091";
import moment from "moment";
const W09F2090 = (props) => {
    const _initDataSource = {
        total: 0,
        rows: []
    };

    const _initFilter = {
        skip: 0,
        limit: 20
    };

    const dataFilterGrid = {
        DateFrom: null,
        DateTo: null,
        Employee: "",
        AppStatusID: "",
        DepartmentID: "",
        DivisionID: "",
        DutyID: "",
    };

    const _w84F3005PopupData = {
        TransID: null
    };

    const _dataPopup = {
        mode: "",
        TransID: "",
        AppStatusID: false,
    };

    const  {getCboAppStatus, getCboDepartments, getCboDivisions, getCboDuty} = props;
    const [dataGrid,setDataGrid] = useState(_initDataSource);
    const [dataCboEmployees, setDataCboEmployees] = useState({rows: [], total: 0});
    const [iPermission,setPermission] = useState({W09F2090: 0, W09F5602: 0});
    const [dataPopup, setDataPopup] = useState(_dataPopup);
    const [dataFilter, setDataFilter] = useState(dataFilterGrid);
    const [loading, setLoading] = useState(false);
    const [loadingGrid, setLoadingGrid] = useState(false);
    const [showW84F3005Popup, setShowW84F3005Popup] = useState(false);
    const [showW09F2091Popup, setShowW09F2091Popup] = useState(false);
    const [rangeDate, setRangeDate] = useState([]);
    const filterCboEmployees = useRef({skip: 0, limit: 20, strSearch: ""});
    const filterGrid = useRef(_initFilter);
    const w84F3005PopupData = useRef(_w84F3005PopupData);

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W09F2090",
            HostID: "",
            Language: Config.language || "84",
            skip: filterCboEmployees.current.skip,
            limit: filterCboEmployees.current.limit,
            search: filterCboEmployees.current.strSearch
        };
        setLoading(true);
        props.generalActions.getCboEmployees(param, (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const rows = data?.rows || data;
                const total = data?.total  || data.length;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total: total,
                })
            }
        });
    };

    const loadCboAppStatus = () => {
        const params = {
            Language: Config.language || 84
        };
        setLoading(true);
        props.w09f2090Actions.getCboAppStatus(params, (errors) => {
            setLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    const loadCboDivision = () => {
        setLoading(true);
        props.w09f2090Actions.getCboDivision((errors) => {
            setLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    const loadCboDepartment = () => {
        const params = {
            DivisionID: ""
        };
        setLoading(true);
        props.w09f2090Actions.getCboDepartment(params, (errors) => {
            setLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };


    const loadCboDuty = () => {
        props.generalActions.getCboDuty2(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadGrid = (TransID = "") => {
        const params = {
            TransID: TransID,
            DivisionID: dataFilter?.DivisionID || "",
            DepartmentID: dataFilter?.DepartmentID || "",
            AppStatusID:  String(dataFilter?.AppStatusID ?? ""),
            DutyID: dataFilter?.DutyID || "",
            EmployeeID: dataFilter?.Employee || "",
            FormID : "W09F2090",
            Language: Config.language || 84,
            DateFrom: dataFilter?.DateFrom || null,
            DateTo: dataFilter?.DateTo || null,
            skip: filterGrid.current.skip,
            limit: filterGrid.current.limit,
        };
        setLoadingGrid(true);
        props.w09f2090Actions.getDataGrid(params, (errors, data) => {
            setLoadingGrid(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if(data) {
                const rows = data?.rows || data;
                const total = data?.total || data.length;
                setDataGrid({
                    rows: rows,
                    total: total
                })
            }
        });
    };

    useEffect(() => {
        const loadPermission = () => {
             props.generalActions.getPermission(["W09F2090", "W09F5602"], (arrPer) => {
                 const permissions = {};
                 if(Array.isArray(arrPer)) {
                     arrPer.forEach(per => {
                         permissions[per.FormID] = per?.IsPermission || 0;
                     });
                 }
                 setPermission(permissions);
            })
        };
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(iPermission?.W09F2090) {
            loadGrid();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[iPermission?.W09F2090]);

    useEffect(() => {
        Config.callChildForm({
            FormID: "W09F2090",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: dataGrid.rows,
            onLoad: (params) => loadGrid(params?.ID ?? ""),
            onAction: (e, data) => onView({ row: { data: data } })
        }, props);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });


    const setData = (obj) => {
        setDataFilter({...dataFilter,...obj})
    };

    const onHistoryApproval = (e, data = {}) => {
        w84F3005PopupData.current.TransID = data.TransID;
        setShowW84F3005Popup(true);
    };

    const onLoadDataFilter = () => {
        loadCboDuty();
        loadCboAppStatus();
        loadCboDepartment();
        loadCboDivision();
        loadCboEmployees();
    };

    const handleChange = (key,e) => {
        if(!key ) return false;
        const value = e?.value ?? e?.target?.value ?? "";
        switch (key) {
            case "RangePicker":
                const dateFrom = Array.isArray(value) ? value[0] : value;
                const dateTo = Array.isArray(value) ? value[1] : value;
                setData({
                    DateFrom: dateFrom,
                    DateTo: dateTo
                });
                setRangeDate([dateFrom, dateTo]);
                break;
            case "AppStatusID":
                setData({AppStatusID: value});
                break;
            case "DepartmentID":
                setData({DepartmentID: value});
                break;
            case "DivisionID":
                setData({DivisionID: value});
                break;
            case "DutyID":
                setData({DutyID: value});
                break;
            case "Employee":
                setData({Employee: value});
                break;
            default:
                break;
        }
    };

    const onAdd = () => {
        setDataPopup({
            mode: "add"
        });
        setShowW09F2091Popup(true)
    };

    const onEdit = (e) => {
        if(!e) return false;
        const {data} = e;
        setDataPopup({
            mode: "edit",
            TransID: data?.TransID || "",
            AppStatusID: data?.AppStatusID !== 0
        });
        setShowW09F2091Popup(true)
    };

    const onView = (e) => {
        if(!e) return false;
        const {data} = e.row;
        setDataPopup({
            mode: "view",
            TransID: data?.TransID || "",
            AppStatusID: data?.AppStatusID !== 0
        });
        setShowW09F2091Popup(true)
    };

    const onDelete = (e) => {
        if(!e) return false;
        const {data} = e.row;
        const params = {
            TransID: data?.TransID || ""
        };
        Config.popup.show('YES_NO',Config.lang("Ban_co_chac_chan_xoa"),
            () =>{
                props.w09f2090Actions.deleteRow(params, (errors, data) => {
                    if (errors) {
                        Config.popup.show('ERROR', errors);
                        return false;
                    }
                    if (data.Status === 0) {
                        Config.notify.show('success', Config.lang("Xoa_thanh_cong"), 2000);
                        loadGrid();
                    }
                })
            });
    };

    const onCloseModal = (isReload) => {
        if(isReload) {
            loadGrid();
        }
        setShowW09F2091Popup(false);
    };

    const onChangePage = (page) => {
        filterGrid.current.skip = page * filterGrid.current.limit;
        loadGrid();
    };

    const onChangePerPage = (perPage) => {
        filterGrid.current.skip = 0;
        filterGrid.current.limit = perPage;
        loadGrid();
    };

    const renderButtonAction = (e) => {
        if(!e) return null;
        const {data} = e.row;
        return (
            <GridActionBar>
                <div className="">
                    <ButtonIcon
                        circular
                        viewBox
                        viewType={"text"}
                        name={"history"}
                        disabled={iPermission?.W09F2090 <= 0}
                        onClick={() => onHistoryApproval(e, data)}
                    />
                    <ButtonIcon
                        circular
                        viewBox
                        viewType={"text"}
                        name={"view"}
                        disabled={iPermission?.W09F2090 <= 2 || data?.AppStatusID !== 0}
                        onClick={() => onEdit(e)}
                    />
                    <ButtonIcon
                        circular
                        viewBox
                        viewType={"text"}
                        name={"delete"}
                        disabled={iPermission?.W09F2090 <= 3 || data?.IsUpdate !== 0}
                        onClick={() => onDelete(e)}
                    />
                </div>
            </GridActionBar>
        );
    };

    const renderFilters = () => {
        return (
            <Filter
                isUseDDCore={true}
                openOnFieldClick={true}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                onOpenLoaded={onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                   <Col xs={12}>
                                       <DateRangePicker
                                           clearAble
                                           controls
                                           value={rangeDate}
                                           viewType={"outlined"}
                                           label={Config.lang("Ngay_hieu_luc")}
                                           onChange={e => handleChange("RangePicker", e)}
                                           placeholder={"DD/MM/YY - DD/MM/YY"}
                                           returnFormat={"YYYY-MM-DD"}
                                       />
                                   </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"DutyID"}
                                            displayExpr={"DutyName"}
                                            label={Config.lang("Chuc_danh_kiem_nhiem")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => handleChange("DutyID", e)}
                                            dataSource={getCboDuty}
                                            loading={loading}
                                            value={dataFilter?.DutyID}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"DepartmentID"}
                                            displayExpr={"DepartmentName"}
                                            label={Config.lang("Phong_ban")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => handleChange("DepartmentID", e)}
                                            dataSource={getCboDepartments}
                                            loading={loading}
                                            value={dataFilter?.DepartmentID}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"DivisionID"}
                                            displayExpr={"DivisionName"}
                                            label={Config.lang("Don_vi")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => handleChange("DivisionID", e)}
                                            dataSource={getCboDivisions}
                                            loading={loading}
                                            value={dataFilter?.DivisionID}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"AppStatusID"}
                                            displayExpr={"AppStatusName"}
                                            label={Config.lang("Trang_thai_duyet")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => handleChange("AppStatusID", e)}
                                            dataSource={getCboAppStatus}
                                            loading={loading}
                                            value={dataFilter?.AppStatusID}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Dropdown
                                            clearAble
                                            dataSource={dataCboEmployees?.rows || []}
                                            total={dataCboEmployees?.total ?? 0}
                                            skip={filterCboEmployees.current.skip}
                                            limit={filterCboEmployees.current.limit}
                                            value={dataFilter?.Employee}
                                            loading={loading}
                                            viewType={"outlined"}
                                            valueExpr={"EmployeeID"}
                                            keyExpr={"EmployeeName"}
                                            searchDelayTime={1000}
                                            label={Config.lang("Nhan_vien")}
                                            placeholder={Config.lang("Chon")}
                                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                                            onChange={(e) => handleChange("Employee", e)}
                                            onInput={(e) => {
                                                filterCboEmployees.current.strSearch = e.target.value;
                                                filterCboEmployees.current.skip = 0;
                                                loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                filterCboEmployees.current.skip = e.skip;
                                                filterCboEmployees.current.limit = e.limit;
                                                loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <Button
                                    color={"primary"}
                                    startIcon={"search"}
                                    viewType={'outlined'}
                                    text={Config.lang('Tim_kiem')}
                                    onClick={loadGrid}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        )
    };

    const renderStatus = (e) => {
        if(!e) return null;
        const {data} = e.row;
        return <Status data={data} />;
    };

    const cellRenderEmployee = (e) => {
        if(!e) return null;
        const {data} = e;
        const user = Config.getUser({EmployeeID: data?.EmployeeID || null});
        const userPictureURL = user?.UserPictureURL || "";
        const info = {
            [Config.lang('Nhan_vien')]: `${user?.EmployeeID} - ${user?.EmployeeName}`,
            [Config.lang('Phong_ban')]: user?.DepartmentName,
            [Config.lang('Du_an')]: user?.ProjectName,
            [Config.lang('Chuc_vu')]: user?.DutyName,
            [Config.lang('Ngay_vao_lam')]: Config.convertDate(user?.DateJoined, '', 'L'),
        };
        return (
            <div className={"display_row align-center"}>
                {(userPictureURL) ?
                    (
                        <Avatar
                            width={64}
                            height={64}
                            data={info}
                            src={userPictureURL.indexOf("http") < 0
                                ? Config.getCDNPath() + userPictureURL
                                : userPictureURL}
                            readOnly
                            hoverAble
                        />
                    )
                    : (
                        <Avatar
                            width={64}
                            height={64}
                            readOnly
                            hoverAble
                            src={require("../../../../assets/images/general/user_default.svg")}
                        />
                    )}
                <div className={"mgl15"}>
                        <Typography type={"h3"}> {user?.EmployeeName || ""}</Typography>
                        <Typography type={"p1"}> {user?.EmployeeID || ""}</Typography>
                </div>
            </div>
        )
    };

    const cellRenderInfo = (e) => {
        if(!e) return null;
        const { data } = e || {};
        const validdate = moment(data.Validdate).isValid() ? moment(data.Validdate).format("DD/MM/YYYY") : null;
        return (
            <div className={"pdt10 pdb10 "}>
                {data?.NewDutyName ? (
                    <div>
                        <Typography type={"h3"}> {Config.lang("Kiem_nhiem")}: {data.NewDutyName || ""}</Typography>
                    </div>
                ) : ""}
                {data?.Validdate ? (
                    <div>
                       <Typography type={"p1"}> {Config.lang("Ngay_hieu_luc")}: {validdate}</Typography>
                    </div>
                ) : ""}
                {Number(data.IsSalaryAdjustment) >= 0 ? (
                    <Checkbox
                        label={Config.lang("Dieu_chinh_luong")}
                        checked={!!data.IsSalaryAdjustment}
                        readOnly
                    />
                ) : ""}
            </div>
            );
    };

    const cellRenderNote = (e) => {
        if(!e) return null;
        const {data} = e;
        return (
            <div className={"pdt10 pdb10 display_row"}>
                {data?.Note ? (
                    <>
                        <Typography style={{whiteSpace: "nowrap"}} className={"mgr5"}>{Config.lang("Ghi_chu")} :</Typography>
                        <Tooltip
                            tooltipMaxWidth={400}
                            style={{overflow: "hidden",textOverflow:"ellipsis"}}
                            title={data?.Note || ""}
                        >
                            {data?.Note || ""}
                        </Tooltip>
                    </>
                ) : ""}
            </div>
        )
    };
    if(!iPermission?.W09F2090) return false;
    return (
        <FormGroup>
            <ActionToolbar title={Config.lang("De_xuat_kiem_nhiemU")}>
                <Button
                    color={"primary"}
                    viewType={"filled"}
                    startIcon={"AddCircle"}
                    style={{textTransform: "uppercase"}}
                    disabled={iPermission?.W09F2090 <= 1}
                    text={Config.lang('Them')}
                    onClick={onAdd}
                />
            </ActionToolbar>
            <W84F3005 open={showW84F3005Popup} onClose={() => setShowW84F3005Popup(false)}
                      FormID="W09F2090" TransID={w84F3005PopupData.current.TransID}/>
            {showW09F2091Popup ?
                <W09F2091
                    iPermission={iPermission}
                    data={{
                        mode: dataPopup.mode,
                        TransID: dataPopup.TransID,
                        AppStatusID: dataPopup.AppStatusID
                    }}
                    open={showW09F2091Popup}
                    onClose={(isReload) => onCloseModal(isReload)} />
                : ""}

            <div className={"hidden"}>{renderFilters()}</div>
            <FormGroup>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <GridContainer
                            style={{ border: "none" }}
                            keyExpr={"TransID"}
                            dataSource={dataGrid.rows || []}
                            totalItems={dataGrid.total || 0}
                            showRowLines={true}
                            showBorders={false}
                            showColumnLines={false}
                            showColumnHeaders={false}
                            noDataText={Config.lang("No_data")}
                            onDbCellClick={onView}
                            loading={loadingGrid}
                            rowAlternationEnabled={false}
                            hoverStateEnabled={true}
                            itemPerPage={filterGrid.current.limit}
                            skipPerPage={filterGrid.current.skip}
                            typePaging={"remote"}
                            onChangePage={onChangePage}
                            onChangePerPage={onChangePerPage}
                        >
                            <Column
                                width={150}
                                alignment={"right"}
                                allowEditing={false}
                                cellRender={renderButtonAction}
                                visible={Config.isMobile}
                            />
                            <Column
                                dataField={"TransID"}
                                visible={false}
                            />
                            <Column
                                dataField={"AppStatusID"}
                                visible={false}
                            />
                            <Column
                                width={300}
                                cellRender={cellRenderEmployee}
                                alignment={"left"}
                            />
                            <Column
                                minWidth={300}
                                cellRender={cellRenderInfo}
                                alignment={"left"}
                            />
                            <Column
                                width={200}
                                cellRender={cellRenderNote}
                                alignment={"left"}
                            />
                            <Column
                                cellRender={renderStatus}
                                alignment={"center"}
                                width={200} />
                            <Column
                                fixed={true}
                                fixedPosition={"right"}
                                visible={!Config.isMobile}
                                cellRender={renderButtonAction}
                            />
                        </GridContainer>
                    </Col>
                </Row>
            </FormGroup>
        </FormGroup>
    )
};

export default connect((state => ({
        getCboDuty: state.general.getCboDuty2,
        getCboAppStatus: state.W09F2090.getCboAppStatus,
        getCboDepartments: state.W09F2090.getCboDepartments,
        getCboDivisions: state.W09F2090.getCboDivisions,
    })),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w09f2090Actions: bindActionCreators(W09F2090Actions, dispatch),
    }))(W09F2090);
