/**
 * @copyright 2023 @ DigiNet
 * @author TRNGHOANG
 * @create 08/03/2023
 * @Example
 */
import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import * as W09F2081Actions from "../../../../redux/W0X/W09F2081/W09F2081_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Column } from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Checkbox,
    Dropdown,
    TreeView,
    Divider,
    ButtonIcon,
    Row,
    Col,
    Typography,
    ModalFooter,
    TextInput,
} from "diginet-core-ui/components";
import { makeStyles, useTheme } from "diginet-core-ui/theme";
const { spacing, colors } = useTheme();

const useStyle = makeStyles(() => ({
    animation: {
        transition: "all 1s",
    },
    noWrap: {
        whiteSpace: "pre",
    },
    leftBox: {
        paddingRight: spacing(4),
        position: "relative",
        transition: "all 1s",
        overflow: "hidden",
    },
    divider: {
        position: "absolute",
        right: 0,
        top: 0,
        width: 1,
        height: "100%",
    },
    hideCloseButton: {
        "&#W09F2081PopupHeader":{
            "& .DGN-UI-Modal-Header-Title": {
                display: "none",
            }
        }
    }
}));

const W09F2081Popup = props => {
    const { open, onClose, data, saveApprovals } = props;

    const [loading, setLoading] = useState(false);
    const [minimum, setMinimum] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [isShowSelected, setIsShowSelected] = useState(false);

    const [dataOrg, setDataOrg] = useState([]);
    const [dataProject, setDataProject] = useState({ rows: [], total: 0 });
    const [dataDuty, setDataDuty] = useState([]);
    const [dataGrid, setDataGrid] = useState({ rows: [], total: 0 });
    const [dataDepartment, setDataDepartment] = useState([]);

    const [cboOrgLoading, setCboOrgLoading] = useState(false);
    const [cboProjectLoading, setCboProjectLoading] = useState(false);
    const [cboDutyLoading, setCboDutyLoading] = useState(false);

    const selectedRowDatas = useRef(null);
    const changePage = useRef(false);
    const changeShow = useRef(false);
    const tmpCurrentSelectedRowKeys = useRef([]);
    const timer = useRef(null);


    const gridRef = useRef(null);
    const [filterSearch, setFilterSearch] = useState({
        EmployeeID: "",
        ProjectID: [],
        OrgChartID: [],
        DepartmentID: [],
        DutyID: [],
    });
    const filter = useRef({
        skip: 0,
        limit: 10,
    })
    const filterCboProjects = useRef({
        skip: 0,
        limit: 50,
    });
    const filterCboDuty = useRef({
        skip: 0,
        limit: 50,
    })
    const filterCboDepartment = useRef({
        skip: 0,
        limit: 50,
    })

    const dispatch = useDispatch();

    const classes = useStyle();

    useEffect(() => {
        loadDataOrgChart(); 
        loadCboProjects();
        loadCboDuty();
        loadCboDepartment();
        loadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Func thay đổi data filter
     * @param key
     * @param evt
     */
    const filterChange = (key, evt) => {
        if (!key || !evt) return;
        const value = evt?.value ?? evt?.target?.value ?? "";
        setFilterSearch({...filterSearch, [key]: value})
    };

    /**
     * load data dropdown cơ cấu tổ chức bên thanh filter trái
     */
    const loadDataOrgChart = () => {
        setCboOrgLoading(true);
        dispatch(
            generalActions.getOrgCharts({}, (error, data) => {
                setCboOrgLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                }
                setDataOrg(data || []);
            })
        );
    };

    /**
     * load data dropdown dự án bên thanh filter trái
     * @param isReset
     */
    const loadCboProjects = isReset => {
        const param = {
            HostID: "",
            FormID: "W09F1120",
            ...filterCboProjects.current,
        };
        setCboProjectLoading(true);
        dispatch(
            generalActions.getCboProjects(param, (error, data) => {
                setCboProjectLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                }
                if (data) {
                    const rows = data && data.rows ? data.rows : data;
                    const total = data && data.total ? data.total : data.length;
                    setDataProject({
                        rows: isReset ? rows : dataProject.rows.concat(rows),
                        total: total,
                    });
                }
            })
        );
    };

    /**
     * load data lưới chính
     */
    const loadGrid = () => {
        const {EmployeeID, DepartmentID, ProjectID, DutyID, OrgChartID} = filterSearch;
        const {skip, limit} = filter.current;
        const params = {
            Employee: EmployeeID,
            DepartmentID,
            ProjectID,
            DutyID,
            OrgChartID,
            FormID: "W09F2081",
            skip,
            limit,
        };
        setLoading(true);
        dispatch(
            W09F2081Actions.loadGrid(params, (error, data) => {
                setLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                }
                setDataGrid(data || []);
            })
        );
    };

    const loadCboDepartment = () => {
        const param = {
            HostID: "",
            FormID: "W09F2081",
            DivisionID: Config.getDivisionID(),
            Language: Config.language || "84",
        };

        props.generalActions.getCboDepartments(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            setDataDepartment(data || []);
        });
    };

    const loadCboDuty = () => {
        setCboDutyLoading(true);
        props.generalActions.getCboDuty2((error, data) => {
            setCboDutyLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            setDataDuty(data || []);
        });
    };

    /**
     * Confirm danh sách nhân viên được chọn phải có đính kèm và mẫu báo cáo
     */
    const confirmSave = () => {
        let dataSelected = gridRef.current.instance.getSelectedRowKeys();
        if (!dataSelected?.length) {
            Config.popup.show("WARNING", Config.lang("Ban_chua_chon_nhan_vien"));
            return;
        }
        onSave();
    };
    const onCloseWithoutSave = () => {
        const {selectedRow, status, notes} = data;
        const action = status === "approve" ? 1 : 100;
        Config.popup.show("YES_NO", Config.lang("Neu_ban_dong_popup_thi_se_khong_gui_thong_bao"), 
        () => {
            saveApprovals(action, notes, selectedRow);
            onClose()
        } , () => {
            return;
        });
    }
    /**
     * thực thi lưu data cả lưới
     * lưu theo từng dòng: lưu CDN đính kèm thành công => tiếp theo sẽ lưu 1 dòng trên lưới
     * @type {Function}
     */
    const onSave = async () => {
        const {selectedRow, status, notes} = data;
        const action = status === "approve" ? 1 : 100;
        const listEmail = [];
        selectedRowDatas.current.forEach(p => listEmail.push(p.Email));
        setLoading(true);
        const params = {
            VoucherID: selectedRow?.VoucherID,
            EmployeeID: JSON.stringify(selectedRowKeys),
            Email: JSON.stringify(listEmail),
        };
        Promise.all([dispatch(
            W09F2081Actions.choose(params, err => {
                setLoading(false);
                if (err) Config.popup.show("ERROR", err);
                else {
                    onClose && onClose();
                }
            })
        ), saveApprovals(action, notes, selectedRow)])
    };

    const onChangePage = (page) => {
        changePage.current = true;
        filter.current.skip = page * filter.current.limit;
        loadGrid();
    };

    const onChangePerPage = (perPage) => {
        changePage.current = true;
        filter.current.skip = 0;
        filter.current.limit = perPage;
        loadGrid();
    };

    const search = () => {
        changePage.current = true;
        filter.current.skip = 0;
        loadGrid();
    };

    const onSelectionChanged = (e) => {
        let tmpSelectedRowKeys = [...selectedRowKeys];
        let tmpSelectedRowDatas = [...(selectedRowDatas.current ?? [])];

        if (e?.currentDeselectedRowKeys?.length > 0) {
            if (changePage.current && !isShowSelected) {
                changePage.current = false;
            } else {
                e.currentDeselectedRowKeys.forEach((key) => {
                    tmpSelectedRowKeys = tmpSelectedRowKeys.filter((i) => i !== key);
                    tmpSelectedRowDatas = tmpSelectedRowDatas.filter((i) => i.EmployeeID !== key);
                });
            }
        }

        if (e?.currentSelectedRowKeys?.length > 0) {
            e.selectedRowsData.forEach((data) => {
                const idxKey = tmpSelectedRowKeys.indexOf(data?.EmployeeID ?? '');
                const idxData = tmpSelectedRowDatas.findIndex((i) => i.EmployeeID === (data?.EmployeeID ?? ''));
                if (idxKey === -1) {
                    tmpSelectedRowKeys.push(data?.EmployeeID ?? '');
                }
                if (idxData === -1) {
                    tmpSelectedRowDatas.push(data);
                }
            });
            changePage.current = false;
        }

        setSelectedRowKeys(tmpSelectedRowKeys);
        selectedRowDatas.current = tmpSelectedRowDatas;
    };

    const changeTypeShow = (e) => {
        if (!e) return false;
        changePage.current = true;
        changeShow.current = true;
        const value = e?.value ?? false;
        setIsShowSelected(value);
    };

    //true: Con key chua duoc select; false: da select het..
    const _checkSelectedRowsKeys = (dataSource) => {
        let isCheck = false;
        if (dataSource && dataSource.length > 0 && selectedRowDatas.current?.length > 0) {
            const dataKeys = dataSource.map(d => d.EmployeeID);
            for (let key of selectedRowDatas.current) {
                if (!dataKeys.includes(key)) {
                    isCheck = true;
                    break;
                }
            }
        }
        return isCheck;
    };


    let _dataGrid = dataGrid.rows;
    let _selectedRowKeys = selectedRowKeys;
    if (isShowSelected) {
        tmpCurrentSelectedRowKeys.current = selectedRowKeys;
        if (selectedRowDatas.current) {
            _dataGrid = selectedRowDatas.current;
            if (_selectedRowKeys.length === tmpCurrentSelectedRowKeys.current.length) changeShow.current = false;
            _selectedRowKeys = tmpCurrentSelectedRowKeys.current;
        }
    } else if (changeShow.current) {
        if (!_checkSelectedRowsKeys(_dataGrid)) changeShow.current = false;
    }

    return (
        <>
            <Modal open={open} pressESCToClose={false}>
                <ModalHeader showClose={false}>
                    <Typography type="h3">{Config.lang("Bo_sung_nguoi_nhan_thong_bao_va_mail")}</Typography>
                    <ButtonIcon name="close" onClick={onCloseWithoutSave} viewType="text" circular />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12} sm={minimum ? 1 : 4} className={classes.leftBox}>
                            <Divider className={classes.divider} />
                            <div
                                className={`display_row align-center ${minimum ? "valign-middle" : "align-between"}`}
                                style={{
                                    backgroundColor: colors.fill["header-datagrid"],
                                    height: spacing(10),
                                    padding: spacing([0, !minimum ? 4 : 1]),
                                    position: "absolute",
                                    left: spacing(-1),
                                    top: spacing(4),
                                    width: "100%",
                                }}
                            >
                                <div className={"display_row align-center"} style={{ gap: spacing(2) }}>
                                    <ButtonIcon
                                        color={"primary"}
                                        name={"FilterFilled"}
                                        viewType={"ghost"}
                                        onClick={() => setMinimum(!minimum)}
                                    />
                                    {!minimum && (
                                        <Typography className={classes.noWrap}>
                                            {Config.lang("Tieu_chi_loc")}
                                        </Typography>
                                    )}
                                </div>
                                {!minimum && (
                                    <ButtonIcon
                                        viewType={"ghost"}
                                        name={"ArrowLeft"}
                                        onClick={() => setMinimum(!minimum)}
                                    />
                                )}
                            </div>
                            {!minimum && (
                                <Row style={{ marginTop: spacing(10) }}>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Checkbox
                                            checked={isShowSelected}
                                            label={Config.lang('Chi_hien_thi_du_lieu_da_chon')}
                                            color={'primary'}
                                            onChange={changeTypeShow}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            label={Config.lang("Nhan_vien")}
                                            className={classes.leftColItem}
                                            startIcon={"search"}
                                            viewType="outlined"
                                            placeholder={Config.lang("Nhap_ma_nhan_vien_ten_nhan_vien")}
                                            onChange={e => filterChange("EmployeeID", e)}
                                        />
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            viewType="outlined"
                                            dataSource={dataOrg}
                                            valueExpr={"OrgChartID"}
                                            loading={cboOrgLoading}
                                            displayExpr={"OrgName"}
                                            label={Config.lang("Co_cau_to_chuc")}
                                            multiple
                                            value={filterSearch.OrgChartID}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => filterChange("OrgChartID", e)}
                                        >
                                            <TreeView
                                                allowSearch
                                                dataSource={dataOrg}
                                                disabledRelevantValue
                                                displayExpr={"OrgName"}
                                                id={"OrgChartID"}
                                                multipleValueMode="single"
                                                multiple
                                                onChange={e => filterChange("OrgChartID", e)}
                                                parentID={"OrgChartParentID"}
                                                value={filterSearch.OrgChartID}
                                                valueExpr={"OrgChartID"}
                                            />
                                        </Dropdown>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            allowSearch
                                            clearAble
                                            viewType="outlined"
                                            dataSource={dataProject?.rows || []}
                                            value={filterSearch.ProjectID}
                                            total={dataProject?.total}
                                            displayExpr={"{ProjectID} - {ProjectName}"}
                                            keyExpr={"ProjectName"}
                                            valueExpr={"ProjectID"}
                                            multiple
                                            loading={cboProjectLoading}
                                            label={Config.lang("Du_an")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => filterChange("ProjectID", e)}
                                            onInput={e => {
                                                filterCboProjects.current.search = e.target.value;
                                                filterCboProjects.current.skip = 0;
                                                loadCboProjects(true);
                                            }}
                                            onLoadMore={e => {
                                                filterCboProjects.current.skip = e.skip;
                                                filterCboProjects.current.limit = e.limit;
                                                loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            allowSearch
                                            clearAble
                                            viewType="outlined"
                                            dataSource={dataDepartment || []}
                                            displayExpr={"{DepartmentID} - {DepartmentName}"}
                                            valueExpr={"DepartmentID"}
                                            multiple
                                            loading={cboProjectLoading}
                                            label={Config.lang("Phong_ban")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => filterChange("DepartmentID", e)}
                                            value={filterSearch.DepartmentID}
                                            onInput={e => {
                                                filterCboDepartment.current.search = e.target.value;
                                                filterCboDepartment.current.skip = 0;
                                                loadCboProjects(true);
                                            }}
                                            onLoadMore={e => {
                                                filterCboDepartment.current.skip = e.skip;
                                                filterCboDepartment.current.limit = e.limit;
                                                loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            allowSearch
                                            clearAble
                                            viewType="outlined"
                                            dataSource={dataDuty || []}
                                            displayExpr={"{DutyID} - {DutyName}"}
                                            keyExpr={"DutyName"}
                                            valueExpr={"DutyID"}
                                            multiple
                                            loading={cboDutyLoading}
                                            label={Config.lang("Chuc_danh_cong_viec")}
                                            placeholder={Config.lang("Chuc_danh_cong_viec")}
                                            onChange={e => filterChange("DutyID", e)}
                                            value={filterSearch.DutyID}
                                            onInput={e => {
                                                filterCboDuty.current.search = e.target.value;
                                                filterCboDuty.current.skip = 0;
                                                loadCboDuty(true);
                                            }}
                                            onLoadMore={e => {
                                                filterCboDuty.current.skip = e.skip;
                                                filterCboDuty.current.limit = e.limit;
                                                loadCboDuty();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} className={"display_row align_center valign-bottom"}>
                                        <Button
                                            startIcon={"search"}
                                            text={Config.lang("Tim_kiem")}
                                            viewType="outlined"
                                            color={"primary"}
                                            onClick={search}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        <Col xs={12} sm={minimum ? 11 : 8} className={classes.animation}>
                            <GridContainer
                                reference={ref => (gridRef.current = ref)}
                                dataSource={_dataGrid || []}
                                totalItems={dataGrid.total}
                                keyExpr={"EmployeeID"}
                                pagerFullScreen={false}
                                typePaging={"remote"}
                                showBorders={false}
                                columnAutoWidth={true}
                                loading={loading}
                                height={Config.getHeightGrid() - 180}
                                selection={{
                                    allowSelectAll: true,
                                    mode: "multiple",
                                    selectAllMode: "allPages",
                                    showCheckBoxesMode: "always",
                                }}
                                selectedRowKeys={_selectedRowKeys}
                                onSelectionChanged={onSelectionChanged}
                                onChangePage={onChangePage}
                                onChangePerPage={onChangePerPage}
                                allowColumnResizing={true}
                                onContentReady={(e) => {
                                    if(timer.current) clearTimeout(timer.current);
                                    timer.current = setTimeout(() => {
                                        e.component.selectRows(selectedRowKeys)
                                    }, 300)
                                }}
                            >
                                <Column
                                    caption={Config.lang("Ma_nhan_vien")}
                                    dataField={"EmployeeID"}
                                    alignment={"left"}
                                    width={150}
                                />
                                <Column
                                    caption={Config.lang("nhan_vien")}
                                    dataField={"EmployeeName"}
                                    alignment={"left"}
                                    width={200}
                                />
                                <Column
                                    caption={Config.lang("Dia_chi_email")}
                                    dataField={"Email"}
                                    alignment={"left"}
                                    width={250}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className={"display_row valign-end"}>
                    <Button
                        label={Config.lang("Chon")}
                        viewType={"filled"}
                        loading={loading}
                        disabled={loading}
                        color={"info"}
                        onClick={() => confirmSave()}
                    />
                </ModalFooter>
            </Modal>
        </>
    );
};

W09F2081Popup.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,
    dataDefaults: PropTypes.object,

    onClose: PropTypes.func,
    data: PropTypes.object,
    saveApprovals: PropTypes.func,
};

export default compose(
    connect(
        state => ({
            getCboStatusWork: state.general.getCboStatusWork,
            getCboProjects: state.general.getCboProjects,
            getCboDuty: state.general.getCboDuty2,
        }),
        dispatch => ({
            W09F2081Actions: bindActionCreators(W09F2081Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    )
)(W09F2081Popup);
